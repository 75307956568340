import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


//IMPORTANT PAGES
import Home from './components/Home'
import Books from './components/Books'
import Tutorials from './components/Tutorials';
import Tutorial from './components/Tutorial';
import Advanced from './components/Advanced';
import Videos from './components/Videos';
import Podcasts from './components/Podcasts';
import Smartify from './components/Smartify';
import About from './components/About';
import Footer from './components/Footer';
import Header from './components/Header';
import Med from './components/Med'
import Error from './components/Error';


//MED
import Bioch from './components/courses/Biochemistry';
import Histo from './components/courses/Histology';
import Embry from './components/courses/Embryology';
import Gross from './components/courses/GrossAnatomy';
import Physi from './components/courses/Physiology';
import Clini from './components/courses/ClinicalScience';
import Patho from './components/courses/Pathology';
import Thera from './components/courses/Theraputics';
import Labsc from './components/courses/LabScience';
import Socie from './components/courses/SocietyAndMedicine';
import Publi from './components/courses/PublicHealth';


// CBU NON QUOTA
import BI110 from './components/subjects/BI110';
import CH110 from './components/subjects/CH110';
import PH110 from './components/subjects/PH110';
import MA110 from './components/subjects/MA110';
import CS110 from './components/subjects/CS110';
import LA111 from './components/subjects/LA111';


// FIRST LINKS
import Biology from './components/subjects/Biology';
import Chemistry from './components/subjects/Chemistry';
import English from './components/subjects/English';
import Mathematics from './components/subjects/Mathematics';
import Physics from './components/subjects/Physics';
import Menu from './components/Menu';
import Images from './components/Images';
import SmartifyData from './components/SmartifyData';

//BRAIN BOOSTERS
import Introduction_to_biology from './components/bbs/bio/introduction_to_biology';
import Introduction_to_physics from './components/bbs/phy/introduction_to_physics';
import Introduction_to_mathematics from './components/bbs/mat/introduction_to_mathematics';
import Introduction_to_chemistry from './components/bbs/che/introduction_to_chemistry';
import Cell from './components/bbs/bio/cell';
import Separation from './components/bbs/che/separation_techniques';
import Enzymes from './components/bbs/bio/enzymes';



//END OF BRAIN BOOSTERS



export default function App() {
  return (

    <div style={styles.body}>
      <Header/>
      <Router>
        <Menu/>
        <Routes>
          <Route path='' element={<Home/>}/>
          <Route path='/books' element={<Books/>}/>
          <Route path='/tutorials'>
            <Route index  element={<Tutorial/>}/>

            {/* SENIORS */}
            <Route path="senior"  element={<Tutorials/>}/>
            <Route path="biology" element={<Biology/>}/>
            <Route path="chemistry" element={<Chemistry/>}/>
            <Route path="english" element={<English/>}/>
            <Route path="mathematics" element={<Mathematics/>}/>
            <Route path="physics" element={<Physics/>}/>

            {/* ADVANCED */}
            <Route path='advanced'  element={<Advanced/>}/>
            <Route path="BI110" element={<BI110/>}/>
            <Route path="CS110" element={<CS110/>}/>
            <Route path="MA110" element={<MA110/>}/>
            <Route path="PH110" element={<PH110/>}/>
            <Route path="CH110" element={<CH110/>}/>
            <Route path="LA111" element={<LA111/>}/>

            {/* MED */}
            <Route path="med" element={<Med/>}/>
            <Route path="bioch" element={<Bioch/>}/>
            <Route path="histo" element={<Histo/>}/>
            <Route path="labsc" element={<Labsc/>}/>
            <Route path="publi" element={<Publi/>}/>
            <Route path="embry" element={<Embry/>}/>
            <Route path="gross" element={<Gross/>}/>
            <Route path="socie" element={<Socie/>}/>
            <Route path="patho" element={<Patho/>}/>
            <Route path="physi" element={<Physi/>}/>
            <Route path="clini" element={<Clini/>}/>
            <Route path="thera" element={<Thera/>}/>

          </Route>
          <Route path='/videos' element={<Videos/>}/>
          <Route path='/podcasts' element={<Podcasts/>}/>
          <Route path='/smartify' element={<Smartify/>}/>
          <Route path='/smartify/:id' element={<SmartifyData/>}/>
          <Route path="/images" element={<Images/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='*' element={<Error/>}/>






          {/* Practice Tests Links  */}
          <Route path='introduction_to_biology' element={<Introduction_to_biology/>}/>
          <Route path='introduction_to_physics' element={<Introduction_to_physics/>}/>
          <Route path='introduction_to_mathematics' element={<Introduction_to_mathematics/>}/>
          <Route path='introduction_to_chemistry' element={<Introduction_to_chemistry/>}/>
          <Route path='cell' element={<Cell/>}/>
          <Route path='separation_techniques' element={<Separation/>}/>
          <Route path='enzymes' element={<Enzymes/>}/>



















        </Routes>
      </Router>
      <Footer/>
    </div>
  )
}


const styles = ({
  menu: {
    color: 'red'
  },
  menuBtn: {
    color: 'red'
  },
  body: {
    width: "100%",
    backgroundColor: 'white',
  }
})