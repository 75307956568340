import React from 'react';
import logo from "../media/logo.png";
import bgImageS from "../media/bg.jpg";


export default function Header(){
    return (
        <>
            <div style={styles.headers}>
                <header style={{ 
                    color: 'white',
                    paddingBottom: 10, 
                    fontWeight: 'bolder', 
                    fontSize: 40,
                    fontFamily: '"Rubik Glitch Pop", system-ui',
                    fontWeight: 400,
                    fontStyle: 'normal',
                }}>STudy STar</header>
                <img src={logo} height="150px" width="150px" alt="STudy STar Logo" style={{border: "1px solid white", borderRadius: 130, padding: 10}}/>
            </div>
            
        </>
    )
}

const styles = {
    headers: {
        textAlign: "center", 
        marginBottom: 15, 
        backgroundImage: `linear-gradient(rgba(1,1,1,1), rgba(1,1,1,0)), url(${bgImageS})`, 
        paddingBottom: 15, 
        paddingTop: 20, 
        marginTop: 0, 
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'center', 
        backgroundSize: 'cover'
    }
}