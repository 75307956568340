import {Helmet} from 'react-helmet-async';

// Imports 
import React from 'react';

//Paid tutorials
// import eco from '../media/practice/eco.png'
// import repropa from '../media/practice/reproduction answers.png'
// import simultaneous from '../media/tutorials/sr/mat/simultaneous equations.png'
import hol from '../media/tutorials/sr/bio/holozoic nutrition.png';
import exc from '../media/tutorials/sr/bio/excretion.png';

import nar from '../media/tutorials/sr/eng/narrative composition.png';
import dir from '../media/tutorials/sr/eng/direct and indirect speech.png';

import alg from '../media/tutorials/sr/mat/algebra.png';
import ari from '../media/tutorials/sr/mat/arithmetic progression (AP).png';

import forc from '../media/tutorials/sr/phy/forces.png';
import lig from '../media/tutorials/sr/phy/light.png';

import aci from '../media/tutorials/sr/che/acids, bases and salts.png';
import bas from '../media/tutorials/sr/che/basic calculations.png';


        // Free Tutorials

import mec from "../media/tutorials/sr/phy/mechanics.png";
import trm from "../media/tutorials/sr/bio/transport in man.png";
import ato from "../media/tutorials/sr/che/atomic structure.png";
import coms from "../media/tutorials/sr/eng/comprehension.png";
import intro from "../media/tutorials/sr/mat/introduction to mathematics.png";
// //End of Image Imports

// import rem from "../media/tutorials/sr/bio/reproduction in man.png"


// const message = "https://wa.me/message/WU3R7WHN5WYOE1";




export default function Home(){
    // const click = () => {
    //     alert("You have just clicked on a purchased Tutorial, click OK to continue to WhatsApp")
    // }
    return (
        <div>
            <Helmet>
                <title>STudy STar: Home Page</title>
                <meta 
                    name='description' 
                    content="STudy STar: A Top Student's secret tool"
                    href="/"
                />
                <link rel="canonical" href='/'/>
            </Helmet>
            <br/>
            <h1 style={styles.h1}>HOME PAGE</h1>
            <div style={styles.body}>
                {/* <p style={styles.target}><b>STudy STar</b> will give you everything you need to change from a failure to an academic STar, I highly suggest <b>practice test</b> first found on the Tutorials page after clicking the <b>menu </b>button above.</p> */}
                <div style={styles.subTitle}>
                    
                        </div>
                        {/* <h3 style={styles.subTitle}>New</h3> */}
                        {/* <p style={{fontSize: 20, color: 'black', textAlign: 'center'}}>Every <b style={{color: 'gold'}}>Saturday</b>, we have something <b style={{color: 'aqua'}}>new</b> to share so make sure you check out at <b style={{color: 'red'}}>5 p.m.</b> </p> */}
                        {/* <p style={{fontSize: 20, color: 'black', textAlign: 'center'}}>Merry Christmas and welcome to <b>STudySTar.me</b> </p> */}
                        <p style={{textAlign: 'center', fontSize: 16}}>
                            "The secret to getting ahead is getting started." - Mark Twain
                        </p>
                            <div className='container' style={{fontSize: 16, color: 'black', textAlign: 'center', textDecoration: 'underline'}}>
                                <h4 style={{fontWeight: 'bolder'}}>New This Week</h4></div>
                            <div style={{margin: 10}}>
                            
                            <p style={{textAlign: 'center', fontWeight: 'bolder'}}>
                                <p style={styles.linkCon}>
                                    {/* <b>No New Items Added This Week</b> */}
                                        <a href='/enzymes'> Enzymes [ Brain Booster ]</a>
                                    <br/>
                                </p>

                            </p>
                                
                                {/* <p style={{fontWeight: 'bolder'}}>
                                <p style={{textAlign: 'center', fontWeight: 'bolder'}}>Triple and double combo</p>
                                <p style={{textAlign: 'center'}}><a href='https://www.studystar.me/smartify/7'>One Step Ahead</a></p>
                                    <div className='container'>
                                        <li><a href='https://drive.google.com/uc?id=1hmMuNwGDdyhHdZqY6Qtm7kHhSKIilJE0&export=download'><p>Practice Test: Thermal Physics</p></a></li>
                                    </div>
                                </p> */}
                                
                                {/* <div style={{backgroundColor: 'rgb(0, 166, 81)', textAlign: 'center', borderRadius: 20,}} className="container">
                                    <br/>
                                    <b style={styles.pHeader}>Mindset Change</b><br/>          
                                    <audio src='https://drive.google.com/uc?id=1N7dujkky9la6O877cQsNsMG6KHNG_bHj&export=download' controls style={{width: '98%'}}/>
                                    <br/><br/>
                                </div><br/>
                                

                                <li><a href='https://drive.google.com/uc?id=1Wi8nndsHcktP-b39hapsjNjn_X9mQIwA&export=download'><p>Electricity (Physics) </p></a></li>
                                <li><a href="http://studystar.me/smartify/5">Time: the dimensions you need to know</a></li> */}
                            </div>
                            
                <div className='container'>
                <div className='row'>
                    <div className='col-md-7' style={{textAlign: 'center'}}>

                    <h4 style={styles.subTitle}>TOP Tutorials</h4>
                    <p style={{textAlign: 'center'}}>The best tutorials</p>
                        <a href='https://drive.google.com/uc?id=1sSzdIrjVyQHr2LLEP2xjgmDxDOXGIvT6&export=download' style={styles.a}><img src={hol} height={187.04} width={132.32} alt="Holozoic Nutrition" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1FL42FYsZHYQ1a6FKvXYccgaH7b4bDbDH&export=download' style={styles.a}><img src={exc} height={187.04} width={132.32} alt="Excretion" style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1RuwSCSwQJ4wryoeM0z2vDFQ_jjhzu1pw&export=download' style={styles.a}><img src={nar} height={187.04} width={132.32} alt="Narrative Composition" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1tjIF8qBPjUnzafiyF0HuT4AXxK7mw4BU&export=download' style={styles.a}><img src={dir} height={187.04} width={132.32} alt="Direct and indirect speech" style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1hVD6VN5o86X_8zJItSU4KysWjAoWPiho&export=download' style={styles.a}><img src={alg} height={187.04} width={132.32} alt="Algebra" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1TsP81-ToBdtlPgaL21qPKX25pU04X3ui&export=download' style={styles.a}><img src={ari} height={187.04} width={132.32} alt="Arithmetic Progression" style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=10iwBl2Z9orXFty39UFCOZPSwkaakDL7s&export=download' style={styles.a}><img src={forc} height={187.04} width={132.32} alt="Forces" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1i-mh6cHqYEzduD35WTXoQhrw9rYn0zVM&export=download' style={styles.a}><img src={lig} height={187.04} width={132.32} alt="Light" style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1Y2_P9h9nfLVRll8bzksaj6fn9IdhBPPy&export=download' style={styles.a}><img src={aci} height={187.04} width={132.32} alt="Basic Calculations" className='products' style={styles.img}/></a>
                        <a href='https://drive.google.com/uc?id=1GOKmdbcFgLFCX8KIr2QUPpQf6Hryg3Ie&export=download' style={styles.a}><img src={bas} height={187.04} width={132.32} alt="Acids, bases and salts" style={styles.img}/></a>

                    </div>
                    <div className='col-md-5' style={{textAlign: 'center'}}>
                    <h4 style={styles.subTitle}>MOST DOWNLOADED</h4>
                    <p>Here are top 5 most downloaded tutorials</p>
                        <a href='https://drive.google.com/uc?id=1sSzdIrjVyQHr2LLEP2xjgmDxDOXGIvT6&export=download' style={styles.a}><img src={trm} height={187.04} width={132.32} alt="Transport in Man" className='products' style={styles.img}/></a>
                        {/* <a href='https://drive.google.com/uc?id=1FL42FYsZHYQ1a6FKvXYccgaH7b4bDbDH&export=download' style={styles.a}><img src={gen} height={187.04} width={132.32} alt="Genetics" style={styles.img}/></a> */}
                        <a href='https://drive.google.com/uc?id=1RuwSCSwQJ4wryoeM0z2vDFQ_jjhzu1pw&export=download' style={styles.a}><img src={ato} height={187.04} width={132.32} alt="Atomic Structure" className='products' style={styles.img}/></a>
                        {/* <a href='https://drive.google.com/uc?id=1tjIF8qBPjUnzafiyF0HuT4AXxK7mw4BU&export=download' style={styles.a}><img src={red} height={187.04} width={132.32} alt="Redox Reactions" style={styles.img}/></a> */}
                        <a href='https://drive.google.com/uc?id=1hVD6VN5o86X_8zJItSU4KysWjAoWPiho&export=download' style={styles.a}><img src={coms} height={187.04} width={132.32} alt="Comprehension" className='products' style={styles.img}/></a>
                        {/* <a href='https://drive.google.com/uc?id=1TsP81-ToBdtlPgaL21qPKX25pU04X3ui&export=download' style={styles.a}><img src={con} height={187.04} width={132.32} alt="Condition" style={styles.img}/></a> */}
                        <a href='https://drive.google.com/uc?id=10iwBl2Z9orXFty39UFCOZPSwkaakDL7s&export=download' style={styles.a}><img src={intro} height={187.04} width={132.32} alt="Introduction to Mathematics" className='products' style={styles.img}/></a>
                        {/* <a href='https://drive.google.com/uc?id=1i-mh6cHqYEzduD35WTXoQhrw9rYn0zVM&export=download' style={styles.a}><img src={ang} height={187.04} width={132.32} alt="Circle Properties" style={styles.img}/></a> */}
                        <a href='https://drive.google.com/uc?id=1Y2_P9h9nfLVRll8bzksaj6fn9IdhBPPy&export=download' style={styles.a}><img src={mec} height={187.04} width={132.32} alt="Mechanics" className='products' style={styles.img}/></a>
                        {/* <a href='https://drive.google.com/uc?id=1GOKmdbcFgLFCX8KIr2QUPpQf6Hryg3Ie&export=download' style={styles.a}><img src={wor} height={187.04} width={132.32} alt="Work, Energy and Power" style={styles.img}/></a> */}
                    </div>
                    </div>
                </div>    
                </div>
            </div>
    )
}

const styles = ({
    target: {
        fontFamily: 'tahoma',
        textAlign: 'center',
        fontSize: 18,
    },
    body: {
        fontFamily: 'arial',
        fontSize: 15,
        margin: 10
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 20,
        marginTop: 15, 
        color: 'rgb(40, 150, 200)',
        fontWeight: 'bold'
    },
    items: {
        listStyle: 'none'
    },
    img: {
        marginTop: 10, 
        marginBottom: 10,
        borderRadius: 5
    },
    par: {
        margin: 5,
        fontSize: 16
    },
    a: {
        marginLeft: 1,
        marginRight: 1,
    },
    linkCon: {
        border: "1px solid blue",
        borderRadius: 4,
        padding: 5,
        margin: 5
    },
    border: {
        border: "1px dotted black", 
        margin: "1em",
        borderRadius: 10,
        textAlign: 'center'
    },
    image: {
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5
    },
    para: {
        paddingBottom: 15,
        fontSize: 18,
        fontFamily: 'times new romans',
        color: 'black',
        textAlign: 'left',
        textIndent: 20,
        marginLeft: 4
    },
    tit: {
        fontFamily: 'fantasy',
        marginTop: 10
    },
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'gold',
        fontWeight: 'bolder'
    }
})