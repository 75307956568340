import React, {useState} from 'react';

import { FaCheck } from "react-icons/fa";
import { ImCross } from 'react-icons/im';



export default function Separation_techniques(){
    const [ showResults, setShowResults ] = useState(false)
    const clicked = () => setShowResults(true)
    const [ ans1, setAns1 ] = useState('Pick an answer')
    const correctAnswer1 = 'A';
    const [ans2, setAns2] = useState('Pick an answer')
    const correctAnswer2 = 'B';
    const [ans3, setAns3] = useState('Pick an answer')
    const correctAnswer3 = 'A';
    const [ans4, setAns4] = useState('Pick an answer')
    const correctAnswer4 = 'C';
    const [ans5, setAns5] = useState('Pick an answer')
    const correctAnswer5 = 'B';
    const [ans6, setAns6] = useState('Pick an answer')
    const correctAnswer6 = 'C';
    const [ans7, setAns7] = useState('Pick an answer')
    const correctAnswer7 = 'B';
    const [ans8, setAns8] = useState('Pick an answer')
    const correctAnswer8 = 'A';
    const [ans9, setAns9] = useState('Pick an answer')
    const correctAnswer9 = 'B';
    const [ans10, setAns10] = useState('Pick an answer')
    const correctAnswer10 = 'B';

    return (
        
        <div><br/>
            <div className='container'>
                <p style={{fontSize: 20, textAlign: 'center', textDecoration: 'underline'}}>Separation Techniques</p>
                <p>
                    <ol>
                        <form>
                            <li>
                                A substance that is made up of two or more substances that are not chemically combined is called
                            </li>
                                <ol type='a'>
                                    <li>Mixture</li>
                                    <li>Compound</li>
                                    <li>Separation techniques</li>
                                </ol>
                                <select
                                    value={ans1}
                                    onChange={(e) => setAns1(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans1 === correctAnswer1 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            

                            <br/>
                            <li>
                                Substances that are made up of two or more substances that do not combine uniformly are called
                            </li>
                                <ol type='a'>
                                    <li>Homogeneous</li>
                                    <li>Heterogeneous</li>
                                    <li>Miscible</li>
                                </ol>
                                <select
                                    value={ans2}
                                    onChange={(e) => setAns2(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans2 === correctAnswer2 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}> Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which separation technique involves the use of a filter paper and a funnel?
                            </li>
                                <ol type='a'>
                                    <li>Filtration</li>
                                    <li>Sedimentation</li>
                                    <li>Chromatography</li>
                                </ol>
                                <select
                                    value={ans3}
                                    onChange={(e) => setAns3(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans3 === correctAnswer3 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The process of carefully pouring the liquid into another beaker without taking the solids is called 
                            </li>
                                <ol type='a'>
                                    <li>Sedimentation</li>
                                    <li>Centrifugation</li>
                                    <li>Decantation</li>
                                </ol>
                                <select
                                    value={ans4}
                                    onChange={(e) => setAns4(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans4 === correctAnswer4 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Separation of two immiscible liquids is easily done by
                            </li>
                                <ol type='a'>
                                    <li>Centrifugation</li>
                                    <li>Use of a separating funnel</li>
                                    <li>Distillation</li>
                                </ol>
                                <select
                                    value={ans5}
                                    onChange={(e) => setAns5(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans5 === correctAnswer5 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which of the following involves the use of a filter paper?
                            </li>
                                <ol type='a'>
                                    <li>Centrifugation</li>
                                    <li>Sedimentation</li>
                                    <li>Chromatography</li>
                                </ol>
                                <select
                                    value={ans6}
                                    onChange={(e) => setAns6(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans6 === correctAnswer6 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The solid particles that do not pass through the filter paper are called
                            </li>
                                <ol type='a'>
                                    <li>Suspension</li>
                                    <li>Residue</li>
                                    <li>Filtrate</li>
                                </ol>
                                <select
                                    value={ans7}
                                    onChange={(e) => setAns7(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans7 === correctAnswer7 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The liquid that is collected after distillation is called    
                            </li>
                                <ol type='a'>
                                    <li>Distillate</li>
                                    <li>Filtrate</li>
                                    <li>Distilled fluid</li>
                                </ol>
                                <select
                                    value={ans8}
                                    onChange={(e) => setAns8(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans8 === correctAnswer8 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>  
                            <li>
                                Which of the following involves the use of a fractionating column?
                            </li>
                                <ol type='a'>
                                    <li>Chromatography</li>
                                    <li>Distillation</li>
                                    <li>Centrifugation</li>
                                </ol>
                                <select
                                    value={ans9}
                                    onChange={(e) => setAns9(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans9 === correctAnswer9 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div> : <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                            <br/>
                            <li>
                                The ratio of the distance travelled by the component to the distance travelled by the solvent is called
                            </li>
                                <ol type='a'>
                                    <li>Chromatic ratio</li>
                                    <li>Retention factor</li>
                                    <li>Centrifugar</li>
                                </ol>
                                <select
                                    value={ans10}
                                    onChange={(e) => setAns10(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans10 === correctAnswer10 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ 0 marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                        </form>
                        
                    </ol>
                    <div style={{textAlign: 'center'}}>
                        <superButton style={{ 
                            backgroundColor: 'rgb(12, 168, 140)',
                            padding: 8,
                            borderRadius: 4,
                            cursor: 'pointer'
                        }} onClick={clicked}>Show Results</superButton>
                    </div>
                </p>
            </div>
        </div>
    )
}

// const styles = ({
//     ana: {
//         width: '90%'
//     },
//     p: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 14,
//         marginBottom: 14,
//         listStyle: 'none',
        
//     },
//     li: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 20,
//         marginBottom: 20,
        
//     },
//     h1: {
//         textAlign: 'center', 
//         textDecoration: 'underline', 
//         fontFamily: "tahoma", 
//         textDecorationColor: 'rgb(16, 167, 200)',
//         fontWeight: 900,
//     }
// })