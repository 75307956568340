import React from 'react';


import * as ImIcons from 'react-icons/im';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import * as GiIcons from 'react-icons/gi';
import { MdOutlineOndemandVideo } from 'react-icons/md';


// eslint-disable-next-line
export const Sidebar = [
    {
        title: 'Home',
        path: "/", 
        icon: <AiIcons.AiFillHome/>
    },
    {
        title: 'Books',
        path: "/books", 
        icon: <BsIcons.BsBookHalf/>
    },
    {
        title: 'Tutorials',
        path: "/tutorials", 
        icon: <GiIcons.GiNewspaper/>
    },
    {
        title: 'Videos',
        path: "/videos", 
        icon: <MdOutlineOndemandVideo/>
    },
    {
        title: 'Podcasts',
        path: "/podcasts", 
        icon: <ImIcons.ImPodcast/>
    },
    {
        title: 'Smartify',
        path: "/smartify", 
        icon: <GiIcons.GiBrain/>
    },
    {
        title: 'About',
        path: "/about", 
        icon: <GiIcons.GiHelp/>
    },
]