import {Helmet} from 'react-helmet-async';
import React from 'react'

import intro from '../../media/tutorials/sr/eng/introduction to english.png';
import com from '../../media/tutorials/sr/eng/comparison.png';
import coms from '../../media/tutorials/sr/eng/comprehension.png';
import con from '../../media/tutorials/sr/eng/condition.png';
import cont from '../../media/tutorials/sr/eng/contrast.png';
import dir from '../../media/tutorials/sr/eng/direct and indirect speech.png';
import gra from '../../media/tutorials/sr/eng/grammar and punctuations.png';
import nar from '../../media/tutorials/sr/eng/narrative composition.png';
import rea from '../../media/tutorials/sr/eng/reason.png';
import sum from '../../media/tutorials/sr/eng/summary.png';
import tim from '../../media/tutorials/sr/eng/time.png';


const price = 4;
// const message = "https://wa.me/message/WU3R7WHN5WYOE1";


export default function English() {
  // const click = () => {
  //   alert("You have just clicked on a purchased Tutorial, click OK to continue to WhatsApp")
  // }
  return (
    <div>
      <Helmet>
                <title>English</title>
                <meta 
                    name='description' 
                    content="English? What topic do you wish to understand?"
                    href="/english"
                />
                <link rel="canonical" href='/english'/>
            </Helmet>
      <h2 style={styles.header}>English</h2>
      <h3 style={{textAlign: "center", fontSize: 20, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder', color: 'blue-green'}}>Grade Ten - Twelve</h3>
      <div style={styles.grade}>
        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Introduction to English</x><br/><span className='badge' style={{color: 'rgb(0,176,80)'}}>FREE</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=1eqeI6gv-0v8JEfnGfFLzY1N9pRXPM_pX&export=download'><img src={intro} height={187.04} width={132.32} style={styles.imb} alt="Introduction to English" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>

        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Grammar and Punctuations<br/><span className='badge' style={{color: 'rgb(0,176,80)'}}>FREE</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=1U2Alp96I2mvsD9vyjoeZZd5SlHb17mYe&export=download'><img src={gra} height={187.04} width={132.32} style={styles.imb} alt="Grammar and Punctuations" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>
        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Narrative Composition<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=15ngp6nf5YQ9eT8WwBLWhieb2A3_-Cs-q&export=download'><img src={nar} height={187.04} width={132.32} style={styles.imb} alt="Narrative Composition" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>

        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Comprehension<br/><span className='badge' style={{color: 'rgb(0,176,80)'}}>FREE</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=1hVD6VN5o86X_8zJItSU4KysWjAoWPiho&export=download'><img src={coms} height={187.04} width={132.32} style={styles.imb} alt="Comprehension" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>

        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Summary<br/><span className='badge' style={{color: 'rgb(0,176,80)'}}>FREE</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=1DpWclo0vy0Uh3U7BrYgspA3ybZNNQMXC&export=download'><img src={sum} height={187.04} width={132.32} style={styles.imb} alt="Summary" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>

        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Comparison<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=1hVD6VN5o86X_8zJItSU4KysWjAoWPiho&export=download'><img src={com} height={187.04} width={132.32} style={styles.imb} alt="Comparison" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>

        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Contrast<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=13pGDqx760BrIBEYQ9hO_FtvY6ywkhmlw&export=download'><img src={cont} height={187.04} width={132.32}  style={styles.imb} alt="Contrast" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>

        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Condition<br/><span className='badge' style={{color: 'rgb(0,176,80)'}}>FREE</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=1TsP81-ToBdtlPgaL21qPKX25pU04X3ui&export=download'><img src={con} height={187.04} width={132.32} style={styles.imb} alt="Condition" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>

        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Direct and Indirect Speech<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=1TyqwbzAXnDFglnmZ_804TRG_JSitRemV&export=download'><img src={dir} height={187.04} width={132.32} style={styles.imb} alt="Direct and Indirect Speech" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>

        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Reason<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=1Gj1QKfkLFVQIpEG3iJ0PuY6muFrHEswg&export=download'><img src={rea} height={187.04} width={132.32} style={styles.imb} alt="Reason" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>

        <div style={styles.topicCon}>
          <h4 style={styles.topicSelector}>Time<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
          <p style={styles.image}><a href='https://drive.google.com/uc?id=1Sd4tOQwqXxCB3U7ODWmBfkk4K1HvuuXt&export=download'><img src={tim} height={187.04} width={132.32} style={styles.imb} alt="Time" className="products"/></a></p>
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
          {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
          <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
          {/* <p>
            <audio src='' controls="controls" style={{width: '96%'}}/>
            <video src='' height={150} width={250} controls="controls"/>
          </p> */}
        </div>
      </div>

    </div>
  )
}


const styles = ({
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: 36,
    paddingTop: 12
  },
  topicSelector: {
    textAlign: 'center',
    paddingTop: 5,
    fontSize: 18,
    fontFamily: 'Arial'
  },
  topicCon: {
    border: "2px dotted black",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  image: {
    textAlign: 'center',
  },
  link: {
    padding: 5,
    border: "1px solid black",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: "none",
    color: 'blue'
  }, 
  linkCon: {
    marginBottom: 13,
    marginTop: 13,
    
  },
  price: {
    textDecoration: 'line-through',
    color: 'red',
    fontSize: 17
  },
  new: {
    fontWeight: 'bolder',
    color: 'rgb(12, 200, 150)',
  },
  imb: {
    borderRadius: 5,
  },
  grade: {
    backgroundImage: 'linear-gradient(white, rgb(32, 170, 73), rgb(202,0,5), rgb(0,128,255),white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  }

})