import {Helmet} from 'react-helmet-async';
import React from 'react';

//Images Imported
//Images Done


export default function Biology() {
  
  return (
    <div>
            <Helmet>
                <title>Mathematics</title>
                <meta 
                    name='description' 
                    content="Mathematics? What topic do you wish to understand?"
                    href="/mathematics"
                />
                <link rel="canonical" href='/mathematics'/>
            </Helmet>
      <h2 style={styles.header}>MA110</h2>

      <div className='grade10' style={styles.grade}>
            <div>                
              <a href='https://drive.google.com/uc?id=1H8NxgxAbydswaJzD_SENI_ZN_AxegxnL&export=download' style={{textDecoration: 'none', fontSize: 17, color: 'white'}}><p style={{padding: 12, textAlign: 'center', border: "2px solid gray", marginLeft: "7%", marginRight: "7%"}}>Test 1 (2023)</p></a>
            </div>
            <div>
              <a href='https://drive.google.com/uc?id=1cuABUXFy8SYrbgL5YX6YS2ViO4-Mzgmb&export=download' style={{textDecoration: 'none', fontSize: 17, color: 'white'}}><p style={{padding: 12, textAlign: 'center', border: "2px solid gray", marginLeft: "7%", marginRight: "7%"}}>Test 2 (2023)</p></a>
            </div>
            <div>
            <a href='https://drive.google.com/uc?id=1jDIhGczGoMmAveHFSM8fFmfUhggESnQy&export=download' style={{textDecoration: 'none', fontSize: 17, color: 'white'}}><p style={{padding: 12, textAlign: 'center', border: "2px solid gray", marginLeft: "7%", marginRight: "7%"}}>Sessional Exam (2023)</p></a>
            </div>

      </div>




      
    </div>
  )
}


const styles = ({
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: 36,
    paddingTop: 12
  },
  topicSelector: {
    textAlign: 'center',
    paddingTop: 5,
    fontSize: 18,
    fontFamily: 'Arial'
  },
  topicCon10: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
  },
  topicCon11: {
    border: "2px dotted black",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  topicCon12: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  image: {
    textAlign: 'center',
  },
  link10: {
    padding: 5,
    border: "1px solid white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  link11: {
    padding: 5,
    border: "1px solid black",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  link12: {
    padding: 5,
    border: "1px solid white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  linkCon: {
    marginBottom: 13,
    marginTop: 13,
    
  },
  grade: {
    backgroundImage: 'linear-gradient(yellow, gold, white)', 
    color: 'black', 
    paddingTop: 30, 
    paddingBottom: 30
  }
})