import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import React from 'react';


// Brain Booster Imports


//Images Imported
import intro from '../../media/tutorials/sr/bio/introduction to biology.png';
import coo from '../../media/tutorials/sr/bio/coordination.png';
import den from '../../media/tutorials/sr/bio/dentition.png';
import eco from '../../media/tutorials/sr/bio/ecology.png';
import enz from '../../media/tutorials/sr/bio/enzymes.png';
import exc from '../../media/tutorials/sr/bio/excretion.png';
import gen from '../../media/tutorials/sr/bio/genetics.png';
import gro from '../../media/tutorials/sr/bio/growth and development.png';
import hea from '../../media/tutorials/sr/bio/health and disease.png';
import hol from '../../media/tutorials/sr/bio/holozoic nutrition.png';
import hom from '../../media/tutorials/sr/bio/homeostasis.png';
import nip from '../../media/tutorials/sr/bio/nutrition in plants.png';
import rem from '../../media/tutorials/sr/bio/reproduction in man.png';
import rep from '../../media/tutorials/sr/bio/reproduction in plants.png';
import res from '../../media/tutorials/sr/bio/respiration and gaseous exchange.png';
import cel from '../../media/tutorials/sr/bio/the cell.png';
import ner from '../../media/tutorials/sr/bio/the nervous system.png';
import trm from '../../media/tutorials/sr/bio/transport in man.png';
import trp from '../../media/tutorials/sr/bio/transport in plants.png';
import tro from '../../media/tutorials/sr/bio/tropic and taxic responses.png';


//Global Prices for tutorials

const price = 4
// const message = "https://wa.me/message/WU3R7WHN5WYOE1";

//Images Done


export default function Biology() {
  // const click = () => {
  //   alert("You have just clicked on a purchased Tutorial, click OK to continue to WhatsApp")
  // }
  return (
    <div>
            <Helmet>
                <title>Biology</title>
                <meta 
                    name='description' 
                    content="Biology? What topic do you wish to understand?"
                    href="/biology"
                /> 
                <link rel="canonical" href='/biology'/>
            </Helmet>
      <h2 style={styles.header}>BIOLOGY</h2>

      <div className='grade10' style={styles.grade10}>
      <h3 style={{textAlign: "center", fontSize: 25, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder',  color: 'rgb(12, 245, 96)'}}>Grade Ten</h3>
      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Introduction to Biology</x><br/><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1YwHGmlx1f6TUy_2IaJ4DYgAPxxQ14FP7&export=download'><img src={intro} height={187.04} width={132.32} style={styles.imb} alt="Introduction to Biology" className="products"/></a></p>
        
        <p style={styles.linkCon}><NavLink to='/introduction_to_biology' style={styles.link10BB}>Brain Booster</NavLink><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1Ppt2KzOuIqxmjyUDce4Gg8w6lOCEwWlQ&export=download' style={styles.link10PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1TzXZPPnWBQnW0uMADHjY6rdZAjlhup65&export=download' style={styles.link10PTA}>Practice Test Answers</a></p>
        
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>The Cell<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1hwT3n2z8OjFJKWtfMSttgGJsX6kop2hq&export=download'><img src={cel} height={187.04} width={132.32} style={styles.imb} alt="The Cell" className="products"/></a></p>
        
        <p style={styles.linkCon}><a href='/cell' style={styles.link10BB}>Brain Booster</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1WSRfliBoUbTVgl932TS6_1ehZPIvXbHI&export=download' style={styles.link10PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1VXa_Rqy4W3cWh8ucf75X5WFPtx3X6TXw&export=download' style={styles.link10PTA}>Practice Test Answers</a></p>
        
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Enzymes <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1-pBLKUiMrn0qqAgLb9JWoSrpB867Qj1D&export=download'><img src={enz} height={187.04} width={132.32} style={styles.imb} alt="Enzymes" className="products"/></a></p>
        <p style={styles.linkCon}><a href='/enzymes' style={styles.link10BB}>Brain Booster</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1m3Fmrp4r9yuDyR7ljYANHPqqyeOyatqJ&export=download' style={styles.link10PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1LJ5yCZbx-OvgWjwC1bI9GrGa1YWRMu0R&export=download' style={styles.link10PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Nutrition in Plants <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1EUsBbNF00vnklGWvOIf1s9SAVp9WxCZx&export=download'><img src={nip} height={187.04} width={132.32} style={styles.imb} alt="Nutrition in Plants" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1wQ79dMB_sVbliuCLs18sbJ2xRcD880XF&export=download' style={styles.link10PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1Gx78MzXh71r-sQ35t4Ck_rWYxtf6W5nu&export=download' style={styles.link10PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Respiration and Gaseous Exchange<br/><span className='badge' style={{color: 'yellow'}}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Wi8nndsHcktP-b39hapsjNjn_X9mQIwA&export=download'><img src={res} height={187.04} width={132.32} style={styles.imb} alt="Respiration and Gaseous Exchange" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1UQFYa4T0t3adLYuw-YkhNqFtX25U0w6y&export=download' style={styles.link10PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1cfphZj70MtAG7-zPME_jCiq1j2akavxh&export=download' style={styles.link10PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Holozoic Nutrition <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1l-lOU-M4SRM4flssD2plquj72ri_DKyT&export=download'><img src={hol} height={187.04} width={132.32} style={styles.imb} alt="Holozoic Nutrition" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1KQefnqtWtbjghjuGeIjU_A9M1HEbKwV2&export=download' style={styles.link10PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1rLUnC1tdWPnGEv6r4ufrNvtBi5ahfhVK&export=download' style={styles.link10PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Dentition <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1WT7w1BsNYVMXUkn5rgtMiTYvOIDFK8N5&export=download'><img src={den} height={187.04} width={132.32} style={styles.imb} alt="Dentition" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1FHpolzcJMfj3OcPPMvSZfGfeXMzbofDR&export=download' style={styles.link10PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1TDeuCt7P6FHzuCgjOAdUAvKyffnvrpgq&export=download' style={styles.link10PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Health and Disease <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1oVUrOIZKjkJVfuaT8l4ZDisxYDRQtvQ4&export=download'><img src={hea} height={187.04} width={132.32} style={styles.imb} alt="Health and Disease" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10PTA}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link10PTA}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link10PTA}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>



















      <div className='grade11' style={styles.grade11}>
      <h3 style={{textAlign: "center", fontSize: 25, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder',  color: 'gold', paddingTop: 20}}>Grade Eleven</h3>
      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Excretion</x> <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1XosD2UeZvuAE8jvsOu7SnOgTU4ezQ-Ao&export=download'><img src={exc} height={187.04} width={132.32} style={styles.imb} alt="Excretion" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1PEBQKvDe0lhcyDBgnTWblWNvxdMlhuq8&export=download' style={styles.link11PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1anWiyG2cruKOlX31YqpMWUSaDPCEafrE&export=download' style={styles.link11PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Transport in Plants <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1dBRZyxcW3XW4L-EnoEZBg712cJH2kQc_&export=download'><img src={trp} height={187.04} width={132.32} style={styles.imb} alt="Transport in Plants" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1QnTXxzKv8wPCh0fgGTq3OcxIn270KjQ1&export=download' style={styles.link11PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1FhhkNZsj1eq6WlO7FN8lyyqMcpt45Yl3&export=download' style={styles.link11PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Transport in Man<br/><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1sSzdIrjVyQHr2LLEP2xjgmDxDOXGIvT6&export=download'><img src={trm} height={187.04} width={132.32} style={styles.imb} alt="Transport in Man" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1C9nwTqS4-7M2NcAen6HRS8jRfBIX87Zm&export=download' style={styles.link11PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=12m8_9Qos6IVoAz3RELg9Y-6sbhllgwgz&export=download' style={styles.link11PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Homeostasis <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=19RBlWgWCf9YqvgbLmGA96dwTW--MVkBx&export=download'><img src={hom} height={187.04} width={132.32} style={styles.imb} alt="Homeostasis" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1f7rty-NJ4ktqxrFA7jqFZHgZ1fBsY7n7&export=download' style={styles.link11PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=17T5COcpN-IaQXeJ86u00OJU1pVlFFBMX&export=download' style={styles.link11PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Coordination <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1bSA_QZehzD0yzULNYkE7XDhisQjo4HLc&export=download'><img src={coo} height={187.04} width={132.32} style={styles.imb} alt="Coordination" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1qPUv9ahuN1xbzc_ax91WkEuNBWLawy4t&export=download' style={styles.link11PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1X-nQvFqc93gXRCEkDUFd7cmyCjM-Mk5a&export=download' style={styles.link11PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Nervous System<br/><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1l8--JZxEGhACzPGR_OsrUOlJah_e5W43&export=download'><img src={ner} height={187.04} width={132.32} style={styles.imb} alt="Nervous System" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1vGYd3ChnGTb9S_Zlft1vglOBIljZHhS_&export=download' style={styles.link11PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=18PBrSopJrVjKooGHvhTi9m_ZxyGw_eLH&export=download' style={styles.link11PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>




















      <div className='grade12' style={styles.grade12}>
      <h3 style={{textAlign: "center", fontSize: 25, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder',  color: "red"}}>Grade Twelve</h3>
      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Reproduction in Plants</x> <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1bJkfhekByOjtRYwjb2G-Ik-OCIgZOIqT&export=download'><img src={rep} height={187.04} width={132.32} style={styles.imb} alt="Reproduction in Plants" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=10CMknFFiJeT9SlngLe1VqxcOJFuAlHc5&export=download' style={styles.link12PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1G4wVY-QwLta0vhS0Pdmoo4ISxSC1Dro-&export=download' style={styles.link12PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Reproduction in Animals <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1jp0x4HiBod7Z3eYoRupH6FGM6PC1S8Dp&export=download'><img src={rem} height={187.04} width={132.32} style={styles.imb} alt="Reproduction in Animals" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1GakfyFkztAWhxZQ9x0RQm7FGFNlWMGkB&export=download' style={styles.link12PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1O4Ni_C8dwbIHb4d9mI9nTYNUxoPHyY2x&export=download' style={styles.link12PTA}>Practice Test Answers</a></p> 
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Genetics<br/><span className='badge' style={{color: 'yellow'}}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1FL42FYsZHYQ1a6FKvXYccgaH7b4bDbDH&export=download'><img src={gen} height={187.04} width={132.32} style={styles.imb} alt="Genetics" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=12ogyS73Yik997UY-VIjGRFgirYMvLxqH&export=download' style={styles.link12PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1tyBjjAepNh_SfUVk37RYQwIK-6H7iesB&export=download' style={styles.link12PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/> 
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>


      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Tropism and Taxism <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1YFXguYwn0nmh-MtXSMXevePGul1EJvrh&export=download'><img src={tro} height={187.04} width={132.32} style={styles.imb} alt="Tropism and Taxism" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1Lqp6snjmqbP5W6uzmwk0TxvVexXhZMDw&export=download' style={styles.link12PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1B4kFSRZEyphpXVTV4M-4MF2dVxQS_c24&export=download' style={styles.link12PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Growth and Development <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1b1ZeD5RRbld4DCXqtd7F-6lKwltFmnZt&export=download'><img src={gro} height={187.04} width={132.32} style={styles.imb} alt="Growth and Development" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1dEr20YjLaPTmIJtqwu7h3hNjgaFLvXoZ&export=download' style={styles.link12PT}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1tRZIkxp_106cvs9kdbf1Pa-sGoq4poUC&export=download' style={styles.link12PTA}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Ecology <br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1NubwXfgdUjti1Rm7CAzl35RGochD21Po&export=download'><img src={eco} height={187.04} width={132.32} style={styles.imb} alt="Ecology" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12PTA}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1V0JRKnkh8Ybgz5_h_Lkx295DlngJlHHu&export=download' style={styles.link12PT}>Practice Test</a><br/></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12PTA}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>




      
    </div>
  )
}


const styles = ({
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: 36,
    paddingTop: 12
  },
  topicSelector: {
    textAlign: 'center',
    paddingTop: 5,
    fontSize: 18,
    fontFamily: 'Arial'
  },
  topicCon10: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  topicCon11: {
    border: "2px dotted black",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  topicCon12: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  image: {
    textAlign: 'center',
  },
  link10: {
    padding: 5,
    border: "1px solid white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  link10PT: {
    padding: 5,
    border: "3px dotted grey",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'rgb(0,255,128)',
  }, 
  link10PTA: {
    padding: 5,
    border: "4px dotted white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'rgb(3, 186, 90)',
  },
  link10BB: {
    padding: 5,
    border: "2px dotted black",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'red',
  }, 

  
  
  link11PT: {
    padding: 5,
    border: "3px dotted grey",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'rgb(0,255,128)',
  }, 
  link11PTA: {
    padding: 5,
    border: "4px dotted white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'rgb(3, 186, 90)',
  },
  link11BB: {
    padding: 5,
    border: "2px dotted black",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'red',
  }, 
  

  link12PT: {
    padding: 5,
    border: "3px dotted grey",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'rgb(0,255,128)',
  }, 
  link12PTA: {
    padding: 5,
    border: "4px dotted white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'rgb(3, 186, 90)',
  },
  link12BB: {
    padding: 5,
    border: "2px dotted black",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'red',
  }, 


  linkCon: {
    marginBottom: 16,
    marginTop: 13,
    
  },
  price: {
    textDecoration: 'line-through',
    color: 'red',
    fontSize: 17
  },
  imb: {
    borderRadius: 5
  },
  new: {
    fontWeight: 'bolder',
    color: 'rgb(12, 200, 150)',
  },
  grade10: {
    backgroundImage: 'linear-gradient(white, rgb(0, 128, 255), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  },
  grade11: {
    backgroundImage: 'linear-gradient(white, rgb(32, 170, 73), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  },
  grade12: {
    backgroundImage: 'linear-gradient(white, rgb(100, 100, 100), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  }
})