import React, {useState} from 'react';

import { FaCheck } from "react-icons/fa";
import { ImCross } from 'react-icons/im';

import cel from "../../../media/practice/cell.png"


export default function Cell(){
    const [ showResults, setShowResults ] = useState(false)
    const clicked = () => setShowResults(true)
    const [ ans1, setAns1 ] = useState('Pick an answer')
    const correctAnswer1 = 'A';
    const [ans2, setAns2] = useState('Pick an answer')
    const correctAnswer2 = 'B';
    const [ans3, setAns3] = useState('Pick an answer')
    const correctAnswer3 = 'C';
    const [ans4, setAns4] = useState('Pick an answer')
    const correctAnswer4 = 'C';
    const [ans5, setAns5] = useState('Pick an answer')
    const correctAnswer5 = 'B';
    const [ans6, setAns6] = useState('Pick an answer')
    const correctAnswer6 = 'A';
    const [ans7, setAns7] = useState('Pick an answer')
    const correctAnswer7 = 'C';
    const [ans8, setAns8] = useState('Pick an answer')
    const correctAnswer8 = 'B';
    const [ans9, setAns9] = useState('Pick an answer')
    const correctAnswer9 = 'A';
    const [ans10, setAns10] = useState('Pick an answer')
    const correctAnswer10 = 'A';

    return (
        
        <div><br/>
            <div className='container'>
                <p style={{fontSize: 20, textAlign: 'center', textDecoration: 'underline'}}>Cell</p>
                <p>
                    <ol>
                        <form>
                            <li>A cell is a</li>
                                <ol type='a'>
                                    <li>Basic unit of life</li>
                                    <li>Basic unit of all animals </li>
                                    <li>Part of a larger organism that produces other organisms</li>
                                </ol>
                                <select
                                    value={ans1}
                                    onChange={(e) => setAns1(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans1 === correctAnswer1 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            

                            <br/>
                            <li>
                                Which of the following is a site for production of energy by respiration</li>
                                <ol type='a'>
                                    <li>Nucleus</li>
                                    <li>Mitochondrion</li>
                                    <li>Vacuole</li>
                                </ol>
                                <select
                                    value={ans2}
                                    onChange={(e) => setAns2(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans2 === correctAnswer2 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}> Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which of the following is an example of a unicellular organism    
                            </li>
                                <ol type='a'>
                                    <li>Animal</li>
                                    <li>Plant</li>
                                    <li>Bacteria</li>
                                </ol>
                                <select
                                    value={ans3}
                                    onChange={(e) => setAns3(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans3 === correctAnswer3 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which plant cell has an elongated outgrowth which increases the surface area for faster diffusion during absorption?
                            </li>
                                <ol type='a'>
                                    <li>Phloem</li>
                                    <li>Palisade cell</li>
                                    <li>Root hair cell</li>
                                </ol>
                                <select
                                    value={ans4}
                                    onChange={(e) => setAns4(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans4 === correctAnswer4 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which plant cell transports food from one part of the plant to the next?   
                            </li>
                                <ol type='a'>
                                    <li>Palisade</li>
                                    <li>Phloem</li>
                                    <li>Root hair cell</li>
                                </ol>
                                <select
                                    value={ans5}
                                    onChange={(e) => setAns5(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans5 === correctAnswer5 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Phagocytes defend the body against infection by engulfing and digesting germs. Which of the following is a 
                                correct adaptation of phagocytes?    
                            </li>
                                <ol type='a'>
                                    <li>Lobed nucleus</li>
                                    <li>Presence of a large nucleus and thin cytoplasm</li>
                                    <li>Absence of nucleus</li>
                                </ol>
                                <select
                                    value={ans6}
                                    onChange={(e) => setAns6(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans6 === correctAnswer6 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                A solution that is less concentrated than the other is called    
                            </li>
                                <ol type='a'>
                                    <li>Isotonic</li>
                                    <li>Hypertonic</li>
                                    <li>Hypotonic</li>
                                </ol>
                                <select
                                    value={ans7}
                                    onChange={(e) => setAns7(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans7 === correctAnswer7 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Study the diagram below.
                                <div style={{textAlign: 'center'}}><img src={cel} width={140} alt='cell question'/></div> 
                                Which process is illustrated above?   
                            </li>
                                <ol type='a'>
                                    <li>Cell lysis</li>
                                    <li>Plasmolysis</li>
                                    <li>Crenation</li>
                                </ol>
                                <select
                                    value={ans8}
                                    onChange={(e) => setAns8(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans8 === correctAnswer8 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>  
                            <li>
                                What happens when a plant cell is placed in a hypertonic solution?</li>
                                <ol type='a'>
                                    <li>It loses water by osmosis and it is called Plasmolysis</li>
                                    <li>It loses water by osmosis and it is called Turgidity</li>
                                    <li>It loses water by osmosis and it is called Cell lysis</li>
                                </ol>
                                <select
                                    value={ans9}
                                    onChange={(e) => setAns9(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans9 === correctAnswer9 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div> : <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                            <br/>
                            <li>
                                Which of the following process requires energy?
                            </li>
                                <ol type='a'>
                                    <li>Active transport</li>
                                    <li>Diffusion</li>
                                    <li>Osmosis</li>
                                </ol>
                                <select
                                    value={ans10}
                                    onChange={(e) => setAns10(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans10 === correctAnswer10 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ 0 marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                        </form>
                        
                    </ol>
                    <div style={{textAlign: 'center'}}>
                        <superButton style={{ 
                            backgroundColor: 'rgb(12, 168, 140)',
                            padding: 8,
                            borderRadius: 4,
                            cursor: 'pointer'
                        }} onClick={clicked}>Show Results</superButton>
                    </div>
                </p>
            </div>
        </div>
    )
}

// const styles = ({
//     ana: {
//         width: '90%'
//     },
//     p: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 14,
//         marginBottom: 14,
//         listStyle: 'none',
        
//     },
//     li: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 20,
//         marginBottom: 20,
        
//     },
//     h1: {
//         textAlign: 'center', 
//         textDecoration: 'underline', 
//         fontFamily: "tahoma", 
//         textDecorationColor: 'rgb(16, 167, 200)',
//         fontWeight: 900,
//     }
// })