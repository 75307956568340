import React, {useState} from 'react';

import { FaCheck } from "react-icons/fa";
import { ImCross } from 'react-icons/im';

import diagram from '../../../media/practice/diagram.png';

export default function Introduction_to_chemistry(){
    const [ showResults, setShowResults ] = useState(false)
    const clicked = () => setShowResults(true)
    const [ ans1, setAns1 ] = useState('Pick an answer')
    const correctAnswer1 = 'A';
    const [ans2, setAns2] = useState('Pick an answer')
    const correctAnswer2 = 'A';
    const [ans3, setAns3] = useState('Pick an answer')
    const correctAnswer3 = 'C';
    const [ans4, setAns4] = useState('Pick an answer')
    const correctAnswer4 = 'A';
    const [ans5, setAns5] = useState('Pick an answer')
    const correctAnswer5 = 'B';
    const [ans6, setAns6] = useState('Pick an answer')
    const correctAnswer6 = 'C';
    const [ans7, setAns7] = useState('Pick an answer')
    const correctAnswer7 = 'B';
    const [ans8, setAns8] = useState('Pick an answer')
    const correctAnswer8 = 'C';
    const [ans9, setAns9] = useState('Pick an answer')
    const correctAnswer9 = 'C';
    const [ans10, setAns10] = useState('Pick an answer')
    const correctAnswer10 = 'B';

    return (
        
        <div><br/>
            <div className='container'>
                <p style={{fontSize: 20, textAlign: 'center', textDecoration: 'underline'}}>Introduction to Chemistry</p>
                <p>
                    <ol>
                        <form>
                            <li>
                                Any substance that occupies space and has mass is referred to as _________
                            </li>
                                <ol type='a'>
                                    <li>Atom</li>
                                    <li>Matter</li>
                                    <li>Density</li>
                                </ol>
                                <select
                                    value={ans1}
                                    onChange={(e) => setAns1(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans1 === correctAnswer1 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            

                            <br/>
                            <li>
                                Which of the following is a basic building block of matter
                            </li>
                                <ol type='a'>
                                    <li>Atom</li>
                                    <li>Liquid</li>
                                    <li>Cell</li>
                                </ol>
                                <select
                                    value={ans2}
                                    onChange={(e) => setAns2(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans2 === correctAnswer2 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}> Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which of the following theories states that all matter is made up of tiny particles which are in constant random motion?
                            </li>
                                <ol type='a'>
                                    <li>Solid-liquid theory of matter</li>
                                    <li>Gravitational theory of matter</li>
                                    <li>The kinetic theory of matter</li>
                                </ol>
                                <select
                                    value={ans3}
                                    onChange={(e) => setAns3(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans3 === correctAnswer3 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which state of matter has particles held together by very strong intermolecular forces to the point where the particles
                                can't move but can only vibrate?
                            </li>
                                <ol type='a'>
                                    <li>Solid</li>
                                    <li>Liquid</li>
                                    <li>Gas</li>
                                </ol>
                                <select
                                    value={ans4}
                                    onChange={(e) => setAns4(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans4 === correctAnswer4 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which state of matter has no fixed shape but fixed volume?
                            </li>
                                <ol type='a'>
                                    <li>Solid</li>
                                    <li>Liquid</li>
                                    <li>Gas</li>
                                </ol>
                                <select
                                    value={ans5}
                                    onChange={(e) => setAns5(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans5 === correctAnswer5 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Study the diagram below.
                                <div style={{textAlign: 'center'}}><img src={diagram} width={260} alt='diagram'/></div>
                                The process labelled 2 is called 
                            </li>
                                <ol type='a'>
                                    <li>Condensation</li>
                                    <li>Sublimation</li>
                                    <li>Deposition</li>
                                </ol>
                                <select
                                    value={ans6}
                                    onChange={(e) => setAns6(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans6 === correctAnswer6 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The constant temperature at which a solid changes to a liquid is called 
                            </li>
                                <ol type='a'>
                                    <li>Freezing point</li>
                                    <li>Melting Point</li>
                                    <li>Boiling point</li>
                                </ol>
                                <select
                                    value={ans7}
                                    onChange={(e) => setAns7(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans7 === correctAnswer7 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The amount of liquid or gas diffusing in a unit of time is referred to as     
                            </li>
                                <ol type='a'>
                                    <li>Osmosis</li>
                                    <li>Diffusion</li>
                                    <li>Rate of diffusion</li>
                                </ol>
                                <select
                                    value={ans8}
                                    onChange={(e) => setAns8(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans8 === correctAnswer8 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>  
                            <li>
                                The term used to describe the continuous random motion of particles especially in gasas and liquids is referred to as    
                            </li>
                                <ol type='a'>
                                    <li>Purity of motion</li>
                                    <li>Dilution</li>
                                    <li>Brownian motion</li>
                                </ol>
                                <select
                                    value={ans9}
                                    onChange={(e) => setAns9(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans9 === correctAnswer9 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div> : <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                            <br/>
                            <li>
                                
                                    Which of the following factors determine the purity of a substance
                                </li>
                                <ol type='a'>
                                    <li>Fixed temperature</li>
                                    <li>Fixed density</li>
                                    <li>Fixed volume</li>
                                </ol>
                                <select
                                    value={ans10}
                                    onChange={(e) => setAns10(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans10 === correctAnswer10 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ 0 marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                        </form>
                        
                    </ol>
                    <div style={{textAlign: 'center'}}>
                        <superButton style={{ 
                            backgroundColor: 'rgb(12, 168, 140)',
                            padding: 8,
                            borderRadius: 4,
                            cursor: 'pointer'
                        }} onClick={clicked}>Show Results</superButton>
                    </div>
                </p>
            </div>
        </div>
    )
}

// const styles = ({
//     ana: {
//         width: '90%'
//     },
//     p: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 14,
//         marginBottom: 14,
//         listStyle: 'none',
        
//     },
//     li: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 20,
//         marginBottom: 20,
        
//     },
//     h1: {
//         textAlign: 'center', 
//         textDecoration: 'underline', 
//         fontFamily: "tahoma", 
//         textDecorationColor: 'rgb(16, 167, 200)',
//         fontWeight: 900,
//     }
// })