import {Helmet} from 'react-helmet-async';
import React from 'react';

//Imports of Images
import end from "../media/smartify/end.jpeg";
import how from "../media/smartify/how_to.jpg";
import sev from "../media/smartify/the_seven.jpeg";
import how2 from "../media/smartify/dontfeel.jpg";
import how3 from "../media/smartify/dontfeel2.JPG";
import sev1 from "../media/smartify/theseven.jpg";
import sev2 from "../media/smartify/theseven21.png";
import brain from "../media/smartify/brain.PNG";
import b1 from "../media/smartify/brain 1.JPG";
import b2 from "../media/smartify/brain 2.JPG";
import c1 from "../media/smartify/clock1.jpg";
import c2 from "../media/smartify/clock2.jpg";
import c3 from "../media/smartify/clock3.jpg";
import discipline from "../media/smartify/discipline.jpg";
import discipline1 from "../media/smartify/discipline.jpeg";
import discipline2 from "../media/smartify/self-discipline.png";
import discipline3 from "../media/smartify/discipline 1.jpg";
import osa from "../media/smartify/osa.jpeg"
import osai from "../media/smartify/osai.jpg"
import osas from "../media/smartify/osas.jpg"
import morning from "../media/smartify/morning.jpg"
import afternoon from "../media/smartify/afternoon.jpg"
import night from "../media/smartify/night.jpg"
import spaced_repetition from "../media/smartify/spaced_repetition.jpg"
import spaced_repetition1 from "../media/smartify/spaced_repetition1.jpg"
import spaced_repetition2 from "../media/smartify/spaced_repetition2.jpg"
import effort1 from "../media/smartify/effort1.jpg"
import effort2 from "../media/smartify/effort2.png"
import effort3 from "../media/smartify/effort3.jpg"
import survey1 from "../media/smartify/survey1.jpg"
import survey2 from "../media/smartify/survey2.jpg"
import survey3 from "../media/smartify/survey3.jpg"
import ad from "../media/Social/Ad Campaign.jpg"


//End of Image Imports 

export default function Images(){
    return (
        <div>
            <Helmet>
                <meta name='robots' content='noindex'/>
            </Helmet>
            <br/>
            <h1 style={styles.h1}>IMAGES PAGE</h1>
            <div style={styles.body}>
                <div style={{textAlign: 'center'}}>
                    <img src={end} height={200} width={200} alt='outro'/>
                    <img src={sev} height={200} width={200} alt="the seven habits"/>
                    <img src={how} height={200} width={200} alt="how to study"/>
                    <img src={how2} height={200} width={200} alt="how to study"/>
                    <img src={how3} height={200} width={200} alt="how to study"/>
                    <img src={sev1} height={200} width={200} alt="the seven habits"/>
                    <img src={sev2} height={200} width={200} alt="the seven habits"/>
                    <img src={brain} height={200} width={200} alt="the brain"/>
                    <img src={b1} height={200} width={200} alt="the brain 1"/>
                    <img src={b2} height={200} width={200} alt="the brain 2"/>
                    <img src={c1} height={200} width={200} alt="clock 1"/>
                    <img src={c2} height={200} width={200} alt="clock 2"/>
                    <img src={c3} height={200} width={200} alt="clock 3"/>
                    <img src={discipline} height={200} width={200} alt="discipline"/>
                    <img src={discipline1} height={200} width={200} alt="discipline 1"/>
                    <img src={discipline2} height={200} width={200} alt="discipline 2"/>
                    <img src={discipline3} height={200} width={200} alt="discipline 3"/>
                    <img src={osai} height={200} width={200} alt="discipline 1"/>
                    <img src={osa} height={200} width={200} alt="discipline 2"/>
                    <img src={osas} height={200} width={200} alt="discipline 3"/>
                    <img src={morning} height={200} width={200} alt="morning"/>
                    <img src={afternoon} height={200} width={200} alt="afternoon"/>
                    <img src={night} height={200} width={200} alt="night"/>
                    <img src={spaced_repetition1} height={200} width={200} alt="spaced_repetition1"/>
                    <img src={spaced_repetition} height={200} width={200} alt="spaced_repetition"/>
                    <img src={spaced_repetition2} height={200} width={200} alt="spaced_repetition2"/>
                    <img src={effort1} height={200} width={200} alt="effort1"/>
                    <img src={effort2} height={200} width={200} alt="effort2"/>
                    <img src={effort3} height={200} width={200} alt="effort3"/>
                    <img src={survey1} height={200} width={200} alt="survey1"/>
                    <img src={survey2} height={200} width={200} alt="survey2"/>
                    <img src={survey3} height={200} width={200} alt="survey3"/>
                    <img src={ad} height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>
                    <img src='' height={200} width={200} alt=""/>


                </div>
            </div>
        </div>
    )
}

const styles = ({
    target: {
        fontFamily: 'tahoma',
        textAlign: 'center',
        fontSize: 18
    },
    body: {
        fontFamily: 'arial',
        fontSize: 15,
        margin: 10
    },
    subTitle: {
        textAlign: 'center',
        fontSize: 20,
        marginTop: 15, 
        color: 'rgb(40, 150, 200)'
    },
    items: {
        listStyle: 'none'
    },
    par: {
        margin: 5,
        fontSize: 16
    },
    a: {
        marginLeft: 2,
        marginRight: 2,
    },
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'rgb(12, 143, 100)',
    }, 
    linkCon: {
        border: "1px solid blue",
        borderRadius: 4,
        padding: 5,
        margin: 5
    }
})