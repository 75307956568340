import React from 'react'

export default function Error() {
  return (
    <>
      <div style={{textAlign: 'center', fontFamily: 'arial', marginBottom: 75}}><b>Error</b>: Page not found or the url you entered has been removed.</div>
      <div style={{textAlign: 'center', fontFamily: 'arial', marginBottom: 75}}>Click the menu button above to navigate to a working page</div>
    </>
  )
}
