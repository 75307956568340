import React from 'react'

import intro from '../../media/tutorials/sr/che/introduction to chemistry.png';
import aci from '../../media/tutorials/sr/che/acids, bases and salts.png';
import app from '../../media/tutorials/sr/che/apparatus techniques.png';
import ato from '../../media/tutorials/sr/che/atomic structure.png';
import bas from '../../media/tutorials/sr/che/basic calculations.png';
import bon from '../../media/tutorials/sr/che/bonding.png';
import ele from '../../media/tutorials/sr/che/elements, mixtures and compounds.png';
import met from '../../media/tutorials/sr/che/metals.png';
import org from '../../media/tutorials/sr/che/organic chemistry.png';
import rat from '../../media/tutorials/sr/che/rates of chemical reactions.png';
import red from '../../media/tutorials/sr/che/redox reactions.png';
import sep from '../../media/tutorials/sr/che/separation techniques.png';
import mol from '../../media/tutorials/sr/che/the mole concept.png';
import per from '../../media/tutorials/sr/che/the periodic table.png';


const price = 4
// const message = "https://wa.me/message/WU3R7WHN5WYOE1";

export default function Chemistry() {
    // const click = () => {
    //     alert("You have just clicked on a purchased Tutorial, click OK to continue to WhatsApp")
    //   }
  return (
    <div>
      <h2 style={styles.header}>Chemistry</h2>
      <h3 style={{textAlign: "center", fontSize: 20, fontFamily: 'tahoma', color: 'rgb(12, 245, 96)'}}>Grade Ten</h3>
      <div style={styles.grade10}>
      <div style={styles.topicCon}>
        <h4 style={{color: 'rgb(0, 128, 255)'}}>Introduction to Chemistry<br/><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=15BkUsMLUTxnUS3W9MWTloxqXFiQzIuT_&export=download'><img src={intro} height={187.04} width={132.32} alt="Introduction to Chemistry"/></a></p>
        
        <p style={styles.linkCon}><a href='/introduction_to_chemistry' style={styles.link}>Brain Booster</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1P3pbk_xCRqTr9k8GueRTtWwzK2EcgfGy&export=download' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1lh5anQSeSeWg61rbV4a9dlgBoVkHnwCf&export=download' style={styles.link}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      
      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Apparatus Techniques<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1OSzUFtvCJO7ior2G0dBVDmPQ7ZxX-olU&export=download'><img src={app} height={187.04} width={132.32} alt="Apparatus Techniques"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Separation Techniques<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1duk0BlvG3u759MzR63lB68xOdzq6Lqmq&export=download'><img src={sep} height={187.04} width={132.32} alt="Separation Techniques"/></a></p>
        
        <p style={styles.linkCon}><a href='/separation_techniques' style={styles.link}>Brain Booster</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1N-ABBDDGxHRcLakjEE_0PK5KWZrmGwCu&export=download' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1MOT1X_miygFs_epqvDDtszkYtt-U9Ksk&export=download' style={styles.link}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Atomic Structure<br></br><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1RuwSCSwQJ4wryoeM0z2vDFQ_jjhzu1pw&export=download'><img src={ato} height={187.04} width={132.32} alt="Atomic Structure"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Bonding<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1A-Y9ZF8J9fgs5-f-K-CD4JMelFMspXVe&export=download'><img src={bon} height={187.04} width={132.32} alt="Bonding"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Elements, Mixtures and Compounds<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1wembJp1sd-PvohWtU-T-qCcauFpBlvBZ&export=download'><img src={ele} height={187.04} width={132.32} alt="Elements, Mixtures and Compounds"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Basic Calculations (StoiChiometry)<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1iZsPI7YR32o0tbjrFUdbYK1wkDNbG_Ty&export=download'><img src={bas} height={187.04} width={132.32} alt="Basic Calculations (StoiChiometry)"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1VwF2OIwSl_DrTqP-NzMnXD7HFP59Jghp&export=download' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1sr_Cozi7oaDm_5POFySPwZheOCv5quUf&export=download' style={styles.link}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>







      <h3 style={{textAlign: "center", fontSize: 20, fontFamily: 'tahoma', color: 'gold'}}>Grade Eleven</h3>







      <div style={styles.grade11}>
      <div style={styles.topicCon}>
        <h4 style={{color: 'rgb(32, 170, 73)'}}>Acids, Bases and Salts<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1oLg-jhY0cTmjE7w9QybPpcoRwoiJBOU2&export=download'><img src={aci} height={187.04} width={132.32} alt="Acids, Bases and Salts"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1L29rfnD2lowtQZb5hRxrIdUsHwBVk1AO&export=download' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1HZTugtK1PKFVHZOz_yFB_pTtvD1BdNRC&export=download' style={styles.link}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>The Mole Concept<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1X1PfkWPSEmF-Ble59k9WvroJoq3Kw_t6&export=download'><img src={mol} height={187.04} width={132.32} alt="The Mole Concept"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1giNrbgOCXxeX9mFJqt6peleynMzjKpak&export=download' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1QXLN17N_zCLXftZWVJGqnD2hIpsT02sY&export=download' style={styles.link}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Metals<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Ln7S7dzt4okVntvelguAcTXeYrW_Pd3N&export=download'><img src={met} height={187.04} width={132.32} alt="Metals"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=17XUAAvnNkKbgF_iB4Cgd_bPkhErK7OsF&export=download' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=12zw_9QdbFC3nm7TI34o_8pAbKFG15-9u&export=download' style={styles.link}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Rates of Chemical Reactions<br/><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Ku2wcPSdKbtKoGWmzp1o_xk7Q51_n6LI&export=download'><img src={rat} height={187.04} width={132.32} alt="Rates of Chemical Reactions"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Periodic Table<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1aDzefC6wHHdePBpd7PhSDiPcDV2UwzMe&export=download'><img src={per} height={187.04} width={132.32} alt="Periodic Table"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>











      <h3 style={{textAlign: "center", fontSize: 20, fontFamily: 'tahoma', color: "red"}}>Grade Twelve</h3>






      <div style={styles.grade12}>
      <div style={styles.topicCon}>
        <h4 style={{color: 'rgb(100, 100, 100)'}}>Redox Reactions<br/><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1tjIF8qBPjUnzafiyF0HuT4AXxK7mw4BU&export=download'><img src={red} height={187.04} width={132.32} alt="Redox Reactions"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1fqKmO5eyVEipG3hDPFjHW0bEaxE0g_dW&export=download' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=13IJrOfwFVpC2Mm2wIlUo2PMGX9aZq015&export=download' style={styles.link}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon}>
        <h4 style={styles.topicSelector}>Organic Chemistry<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=163xk3VEmnpxE7K9h8tKb76rz6P2bQvAQ&export=download'><img src={org} height={187.04} width={132.32} alt="Organic Chemistry"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>




    </div>
  )
}



const styles = ({
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: 36
  },
  topicSelector: {
    textAlign: 'center',
    paddingTop: 5,
    fontSize: 18,
    fontFamily: 'Arial'
  },
  topicCon: {
    border: "2px dotted black",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  image: {
    textAlign: 'center',
  },
  link: {
    padding: 5,
    border: "1px solid black",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  linkCon: {
    marginBottom: 13,
    marginTop: 13,
    
  },
  price: {
    textDecoration: 'line-through',
    color: 'red',
    fontSize: 17
  },
  new: {
    fontWeight: 'bolder',
    color: 'rgb(12, 200, 150)',
  },
  grade10: {
    backgroundImage: 'linear-gradient(white, rgb(0, 128, 255), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  },
  grade11: {
    backgroundImage: 'linear-gradient(white, rgb(32, 170, 73), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  },
  grade12: {
    backgroundImage: 'linear-gradient(white, rgb(100, 100, 100), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  }
})