import {Helmet} from 'react-helmet-async';
import React from 'react';

export default function Videos(){
    return (
        <div>
            <Helmet>
                <title>STudy STar: Videos Page</title>
                <meta 
                    name='description' 
                    content="STudy STar: Videos that inspire"
                    href="/videos"
                />
                <link rel="canonical" href='/videos'/>
            </Helmet>
            <br/>
            <h1 style={styles.h1}>VIDEOS PAGE</h1>
            <div className='body'>
                <p style={{textAlign: 'center'}}>This is the Videos page</p>
                <div style={{textAlign: 'center'}}><hr/>
                    <p><b>Algebra Part 4</b></p>
                    <p>In this video I continue algebra and teach: <br/> - Factorisation of Quadratic Expressions <br/> - Algebraic Fractions</p>
                    <p><iframe width="320px" height="250px" src="https://www.youtube.com/embed/FBzXuLykH0o?si=6OhyQ_oGh8el_a1W_" title="Algebra Part 4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></p>
                </div>
                <div style={{textAlign: 'center'}}><hr/>
                    <p><b>Algebra Part 3</b></p>
                    <p>In this video I continue algebra and teach: <br/> - Normal Factorisation <br/> - Defferences of two squares <br/> - Grouped Factorisation</p>
                    <p><iframe width="320px" height="250px" src="https://www.youtube.com/embed/X3ZX8oUu2bE?si=80BUdbc8plnB74H_" title="Algebra Part 3" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></p>
                </div>
                <div style={{textAlign: 'center'}}><hr/>
                    <p><b>Algebra Part 2</b></p>
                    <p>In this video I continue algebra and teach: <br/> - Expansion <br/> - Substitution</p>
                    <p><iframe width="320px" height="250px" src="https://www.youtube.com/embed/pU0Bn8aqgso?si=CKcE374rGDgLabiY" title="Algebra Part 2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></p>
                </div>
                <div style={{textAlign: 'center'}}><hr/>
                    <p><b>Algebra Part 1</b></p>
                    <p>
                        Grade 8 and 10 basic algebra. It is the first video you should watch if you want to build your knowledge in math on a strong 
                        foundation. In this video I teach: <br/> - Like and Unlike Terms<br/> - Simplification of expressions
                    </p>
                    <p><iframe width="320px" height="250px" src="https://www.youtube.com/embed/BfPdbkeSogY?si=2L1QyJew05IUMYWl" title="Algebra Part 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></p>
                </div>
            </div>
        </div>
    )
}

const styles = {
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'gold',
        fontWeight: 900,
    },
}