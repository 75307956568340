import React, {useState} from 'react';

import { FaCheck } from "react-icons/fa";
import { ImCross } from 'react-icons/im';


export default function Introduction_to_physics(){
    const [ showResults, setShowResults ] = useState(false)
    const clicked = () => setShowResults(true)
    const [ ans1, setAns1 ] = useState('Pick an answer')
    const correctAnswer1 = 'B';
    const [ans2, setAns2] = useState('Pick an answer')
    const correctAnswer2 = 'C';
    const [ans3, setAns3] = useState('Pick an answer')
    const correctAnswer3 = 'A';
    const [ans4, setAns4] = useState('Pick an answer')
    const correctAnswer4 = 'B';
    const [ans5, setAns5] = useState('Pick an answer')
    const correctAnswer5 = 'A';
    const [ans6, setAns6] = useState('Pick an answer')
    const correctAnswer6 = 'C';
    const [ans7, setAns7] = useState('Pick an answer')
    const correctAnswer7 = 'C';
    const [ans8, setAns8] = useState('Pick an answer')
    const correctAnswer8 = 'A';
    const [ans9, setAns9] = useState('Pick an answer')
    const correctAnswer9 = 'A';
    const [ans10, setAns10] = useState('Pick an answer')
    const correctAnswer10 = 'C';

    return (
        
        <div><br/>
            <div className='container'>
                <p style={{fontSize: 20, textAlign: 'center', textDecoration: 'underline'}}>Introduction to Physics</p>
                <p>
                    <ol>
                        <form>
                            <li>
                                Anything that can be measured is referred to as 
                            </li>
                                <ol type='a'>
                                    <li>A unit</li>
                                    <li>A physical quantity</li>
                                    <li>A particle</li>
                                </ol>
                                <select
                                    value={ans1}
                                    onChange={(e) => setAns1(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans1 === correctAnswer1 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            

                            <br/>
                            <li>
                                Units that are used everywhere by scientists to avoid confusion are called
                            </li>
                                <ol type='a'>
                                    <li>Basic units</li>
                                    <li>Derived units</li>
                                    <li>SI units</li>
                                </ol>
                                <select
                                    value={ans2}
                                    onChange={(e) => setAns2(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans2 === correctAnswer2 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}> Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which of the following is a SI symbol for temperature?
                            </li>
                                <ol type='a'>
                                    <li>K</li>
                                    <li>k</li>
                                    <li><sup>o</sup>C</li>
                                </ol>
                                <select
                                    value={ans3}
                                    onChange={(e) => setAns3(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans3 === correctAnswer3 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which of the following is a value for the prefix <b>Nano</b>
                            </li>
                                <ol type='a'>
                                    <li>10<sup>-12</sup></li>
                                    <li>10<sup>-9</sup></li>
                                    <li>10<sup>-6</sup></li>
                                </ol>
                                <select
                                    value={ans4}
                                    onChange={(e) => setAns4(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans4 === correctAnswer4 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The distance between two points is called 
                            </li>
                                <ol type='a'>
                                    <li>Length</li>
                                    <li>Meter</li>
                                    <li>Measuring tape</li>
                                </ol>
                                <select
                                    value={ans5}
                                    onChange={(e) => setAns5(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans5 === correctAnswer5 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Which of the following is an advantage of using a measuring tape?
                            </li>
                                <ol type='a'>
                                    <li>It can only measure 100cm </li>
                                    <li>It can measure very small distances</li>
                                    <li>It is flexible</li>
                                </ol>
                                <select
                                    value={ans6}
                                    onChange={(e) => setAns6(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans6 === correctAnswer6 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                Clean the anvil and the spindle is a precaution for using a 
                            </li>
                                <ol type='a'>
                                    <li>Engineer's Caliper</li>
                                    <li>Verniers' Calipers</li>
                                    <li>Micrometer Screw Guage</li>
                                </ol>
                                <select
                                    value={ans7}
                                    onChange={(e) => setAns7(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans7 === correctAnswer7 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>
                            <li>
                                The clearing of positive and negative errors of the micrometer screw guage is called</li>
                                <ol type='a'>
                                    <li>Zeroing</li>
                                    <li>Erroring</li>
                                    <li>Cleaning</li>
                                </ol>
                                <select
                                    value={ans8}
                                    onChange={(e) => setAns8(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans8 === correctAnswer8 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                            <br/>  
                            <li>
                                The instrument used to measure time intervals is called</li>
                                <ol type='a'>
                                    <li>Pendulum</li>
                                    <li>Stop watch</li>
                                    <li>Durator</li>
                                </ol>
                                <select
                                    value={ans9}
                                    onChange={(e) => setAns9(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans9 === correctAnswer9 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div> : <div style={{color: 'red'}}>Wrong <ImCross/> [0 mark]</div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                            <br/>
                            <li>
                                
                                    The number of complete oscillations per second is called
                                </li>
                                <ol type='a'>
                                    <li>Oscillaion</li>
                                    <li>Period</li>
                                    <li>Frequency</li>
                                </ol>
                                <select
                                    value={ans10}
                                    onChange={(e) => setAns10(e.target.value)}
                                >
                                    <option value="O">Pick an answer</option>
                                    <option value='A'>A</option>
                                    <option value='B'>B</option>
                                    <option value='C'>C</option>
                                </select>
                                <br/>
                                {
                                    showResults? (
                                    <p>
                                        {
                                            ans10 === correctAnswer10 ? <div style={{color: 'green'}}>Correct <FaCheck/> [ 1 mark ]</div>: <div style={{ color: 'red' }}> Wrong <ImCross/> [ 0 marks ] </div>  
                                        }
                                        
                                    </p>): null
                                }
                                
                        </form>
                        
                    </ol>
                    <div style={{textAlign: 'center'}}>
                        <superButton style={{ 
                            backgroundColor: 'rgb(12, 168, 140)',
                            padding: 8,
                            borderRadius: 4,
                            cursor: 'pointer'
                        }} onClick={clicked}>Show Results</superButton>
                    </div>
                </p>
            </div>
        </div>
    )
}

// const styles = ({
//     ana: {
//         width: '90%'
//     },
//     p: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 14,
//         marginBottom: 14,
//         listStyle: 'none',
        
//     },
//     li: {
//         fontFamily: 'arial',
//         textAlign: 'center',
//         marginTop: 20,
//         marginBottom: 20,
        
//     },
//     h1: {
//         textAlign: 'center', 
//         textDecoration: 'underline', 
//         fontFamily: "tahoma", 
//         textDecorationColor: 'rgb(16, 167, 200)',
//         fontWeight: 900,
//     }
// })