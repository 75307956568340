import {Helmet} from 'react-helmet-async';
import React from 'react';

export default function Podcasts(){
    return (
        <div>
            <Helmet>
                <title>STudy STar: Podcast Page</title>
                <meta 
                    name='description' 
                    content="Listen to things that will grow your mind and inspire you to make a change in the world"
                    href="/podcasts"
                />
                <link rel="canonical" href='/'/>
            </Helmet>
            <br/>
            <h1 style={styles.h1}>PODCASTS PAGE</h1>
            <div className='body'>
                
                <div style={{backgroundColor: 'rgb(0, 166, 81)', textAlign: 'center', borderRadius: 20,}} className="container">
                    <br/>
                    <b style={styles.pHeader}>Mindset Change</b><br/>
                    <audio src='https://drive.google.com/uc?id=1N7dujkky9la6O877cQsNsMG6KHNG_bHj&export=download' controls style={{width: '98%'}}/>
                    <br/><br/>
                </div><br/>


                <div style={{backgroundColor: 'rgb(0, 166, 81)', textAlign: 'center', borderRadius: 20,}} className="container">
                    <br/>
                    <b style={styles.pHeader}>The importance of planning</b><br/>
                    <audio src='https://drive.google.com/uc?id=1ptDWgrOUq_Vh4zcXuYDb4oTH054LM_eW&export=download' controls style={{width: '98%'}}/>
                    <br/><br/>
                </div><br/>

                



            </div>
            
        </div>
    )
}

const styles = ({
    pHeader: {
        fontSize: 18,
        fontFamily: 'times new romans',
        fontStyle: 'italic',
        color: 'black'
    },
    para: {
        textAlign: 'center',
        fontFamily: 'arial'
    },
    audio: {
        width: "90%",
    },
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'rgb(150,150,150)',
        fontWeight: 900,
    }
})