import {Helmet} from 'react-helmet-async';
import React from 'react';

//Images Imported
//Images Done


export default function Biology() {
  
  return (
    <div>
            <Helmet>
                <title>Physics</title>
                <meta 
                    name='description' 
                    content="Physics? What topic do you wish to understand?"
                    href="/physics"
                />
                <link rel="canonical" href='/physics'/>
            </Helmet>
      <h2 style={styles.header}>PH110</h2>

      <div className='grade10' style={styles.grade}>
            <div>                
              <a href='https://drive.google.com/uc?id=1FC_g-hNwRpkAeg_lk9-SxD8lQgiEMh0s&export=download' style={{textDecoration: 'none', fontSize: 17, color: 'white'}}><p style={{padding: 12, textAlign: 'center', border: "2px solid gray", marginLeft: "7%", marginRight: "7%"}}>Test 1 (2023)</p></a>
            </div>
            <div>                
              <a href='https://drive.google.com/uc?id=1z2SF7m6Dxo32ssiy15T_G7-_61plMi4P&export=download' style={{textDecoration: 'none', fontSize: 17, color: 'white'}}><p style={{padding: 12, textAlign: 'center', border: "2px solid gray", marginLeft: "7%", marginRight: "7%"}}>Sessional Part 1 (2023)</p></a>
            </div>
            <div>                
              <a href='https://drive.google.com/uc?id=1cBrrETDFa5HMuRToX3zJda11fNaRlgL7&export=download' style={{textDecoration: 'none', fontSize: 17, color: 'white'}}><p style={{padding: 12, textAlign: 'center', border: "2px solid gray", marginLeft: "7%", marginRight: "7%"}}>Sessional Part 2 (2023)</p></a>
            </div>

      </div> 
    </div>
  )
}


const styles = ({
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: 36,
    paddingTop: 12
  },
  topicSelector: {
    textAlign: 'center',
    paddingTop: 5,
    fontSize: 18,
    fontFamily: 'Arial'
  },
  topicCon10: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
  },
  topicCon11: {
    border: "2px dotted black",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  topicCon12: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  image: {
    textAlign: 'center',
  },
  link10: {
    padding: 5,
    border: "1px solid white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  link11: {
    padding: 5,
    border: "1px solid black",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  link12: {
    padding: 5,
    border: "1px solid white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  linkCon: {
    marginBottom: 13,
    marginTop: 13,
    
  },
  grade: {
    backgroundImage: 'linear-gradient(red, rgb(220, 100, 150), white)', 
    color: 'black', 
    paddingTop: 30, 
    paddingBottom: 30
  }
})