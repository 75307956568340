import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import {useParams} from 'react-router-dom';

import end from "../media/smartify/end.jpeg";


export default function SmartifyData() {
    const {id} = useParams();
    const [smartify, setSmartify] = useState([])
    useEffect(()=>{
        //
        fetch(`https://michealzsd.pythonanywhere.com/Data/${id}`, {
            method: 'GET',
            headers: {
                "content-type": "application/json"
            }
        }).then(resp => resp.json())
        .then(resp => setSmartify(resp))
        .catch(error => console.log(error))
    }, [])
  return (
    <div className='container'><br/>
        <div className='container' style={{backgroundColor: 'rgb(250,250,250)', borderRadius: 9}}>
            <h1 style={{
                textAlign: 'center', 
                textDecoration: 'underline', 
                fontFamily: '"Newsreader", serif',
                fontOpticalSizing: 'auto',
                fontWeight: 600,
                fontStyle: 'normal',
                paddingTop: '10px'
            }}>{smartify.title}</h1>
            <p style={{textAlign: 'right'}}><small>{smartify.date}</small><br/><small>{smartify.time}</small></p>
            <p style={{textAlign: 'center'}}><img src={smartify.firstImage} height={180} width={270} alt={smartify.title} style={{borderRadius: 7}}/></p>
            <p style={styles.paragraph}>{smartify.introFirst}</p>
            <p style={styles.paragraph}>{smartify.introSecond}</p>
            <p style={styles.paragraph}>{smartify.introThird}</p>
            <p style={styles.paragraph}>{smartify.introFourth}</p>
            <p style={{textAlign: 'center'}}><img src={smartify.secondImage} height={180} width={270} alt={smartify.title} style={{borderRadius: 7}}/></p>
            <p style={styles.headerParagraphs}>{smartify.fparaBoldOne}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphOne}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwo}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphThree}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphFour}</p>
            <p style={styles.headerParagraphs}>{smartify.firstParagraphBoldTwo}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphFive}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphSix}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphSeven}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphEight}</p>
            <p style={styles.headerParagraphs}>{smartify.firstParagraphBoldThree}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphNine}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTen}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphEleven}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwelve}</p>
            <p style={styles.headerParagraphs}>{smartify.firstParagraphBoldFour}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphThirteen}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphFourteen}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphFifteen}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphSixteen}</p>
            <p style={styles.headerParagraphs}>{smartify.firstParagraphBoldFive}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphSeventeen}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphEighteen}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphNineteen}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwenty}</p>
            <p style={styles.headerParagraphs}>{smartify.firstParagraphBoldSix}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwentyone}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwentytwo}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwentythree}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwentyfour}</p>
            <p style={styles.headerParagraphs}>{smartify.firstParagraphBoldSeven}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwentyfive}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwentysix}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwentyseven}</p>
            <p style={styles.paragraph}>{smartify.firstParagraphTwentyeight}</p>




            <p style={{textAlign: 'center'}}><img src={smartify.thirdImage} height={180} width={270} alt={smartify.title} style={{borderRadius: 7}}/></p>
           
           
            <p style={styles.headerParagraphs}>{smartify.secondParagraphBoldOne}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphOne}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwo}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphThree}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphFour}</p>
            <p style={styles.headerParagraphs}>{smartify.secondParagraphBoldTwo}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphFive}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphSix}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphSeven}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphEight}</p>
            <p style={styles.headerParagraphs}>{smartify.secondParagraphBoldThree}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphNine}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTen}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphEleven}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwelve}</p>
            <p style={styles.headerParagraphs}>{smartify.secondParagraphBoldFour}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphThirteen}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphFourteen}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphFifteen}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphSixteen}</p>
            <p style={styles.headerParagraphs}>{smartify.secondParagraphBoldFive}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphSeventeen}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphEighteen}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphNineteen}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwenty}</p>
            <p style={styles.headerParagraphs}>{smartify.secondParagraphBoldSix}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwentyone}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwentytwo}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwentythree}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwentyfour}</p>
            <p style={styles.headerParagraphs}>{smartify.secondParagraphBoldSeven}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwentyfive}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwentysix}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwentyseven}</p>
            <p style={styles.paragraph}>{smartify.secondParagraphTwentyeight}</p>
            <p style={{textAlign: 'center'}}><img src={end} height={180} width={180} alt={smartify.title}/></p>
            <p style={{textIndent: 20, textAlign: 'right'}}> <b>◼ Posted by</b>: {smartify.poster} </p>
            <p style={{textIndent: 20, textAlign: 'right'}}> <b>◼ Position</b>: {smartify.position}</p>
        </div>

        
    </div>
  )
}

const styles = {
    paragraph: {
        textIndent: 20, 
        fontFamily: '"Raleway", sans-serif',
        fontOpticalSizing: 'auto',
        fontWeight: 300,
        fontStyle: 'normal', 
        fontSize: 17,
    },
    headerParagraphs: {
        fontFamily: '"Raleway", sans-serif',
        fontOpticalSizing: 'auto',
        fontWeight: 800,
        fontStyle: 'normal', 
        fontSize: 17,
    }
}