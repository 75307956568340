import styled from 'styled-components';
import {Link} from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
import React, { useState } from 'react';
import { Sidebar} from './MenuData'
import SubMenu from './SubMenu';
import logo from "../media/logo.png";
// import bgImageS from "../media/bg.jpg";

const Nav = styled.div`
    color: white;
    margin: 0px;
    text-align: center;
`

const NavIconOpener = styled(Link)`
    color: black;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px;
    border: 1px solid black;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bolder;

    &:hover{
        background-color: rgb(12, 225, 105);
        color: white;
    }
`

// const NavIconCloser = styled(Link)`
//     color: white;
//     padding-top: 5px;
//     padding-bottom: 10px;
//     padding-left: 10px;
//     padding-right: 10px;
//     margin-left: 10px;
//     font-size: 20px;
//     border: 2px solid red;
//     border-radius: 10px;
//     font-size: 20px;
//     font-weight: bolder;

//     &:hover{
//         background-color: red;
//         color: black;
//         font-size: 23px;
//     }
// `

//background-image: url(../media/bg.jpg);
const NavNav = styled.nav`
    background-color: black;
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: ${({ sidebar }) => (sidebar ? '0': '-100%')};
    transition: 350ms;
    z-index: 10;
`

const NavWrap = styled.div`
    width: 200px;
`


const Menu = () => {

    const [sidebar, setSidebar] = useState(false)

    const showSideBar = () => setSidebar(!sidebar)
    

    return (
        <>
            <Nav onClick={showSideBar}>
                <NavIconOpener to="#">
                    <FaIcons.FaBars/>
                </NavIconOpener>
            </Nav>
            <NavNav sidebar={sidebar} style={{width: '100%'}}>
                <NavWrap>
                    
                    <div style={{marginTop: 20,}}>
                        <div style={style.menu}>MENU</div>
                        <p style={style.logo}><img src={logo} height={50} width={50} alt="menu_logo"/></p>
                        {/* <NavIconCloser to="#">
                            <AiIcons.AiOutlineClose  onClick={showSideBar}/>
                        </NavIconCloser> */}
                        <div style={{marginTop: 15, paddingBottom: 130 }} onClick={showSideBar}>
                            {Sidebar.map((item, index)=>{
                                return(
                                    <SubMenu item={item} key={index}></SubMenu>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{color: 'white', textAlign: 'center'}}>
                        <p>&copy; STudy STar</p>
                    </div>
                </NavWrap>
                
            </NavNav>
            
            
        </>
    )

}

export default Menu;


const style = {
    menu: {
        textAlign: 'center', 
        marginLeft: -12,
        fontWeight: 'bolder',
        color: 'rgb(110, 200, 225)',
        fontSize: 36,
        textShadow: "-2px -0px 20px rgb(185, 201, 150)",
        fontFamily: '"Rubik Glitch Pop", system-ui',
        fontWeight: 400,
        fontStyle: 'normal',
    },
    logo: {
        textAlign: 'center',
        marginLeft: -18,
    }
}