import React from 'react'

export default function Histology() {
  return (
    <div><br/>
        <h3 style={{textAlign:'center', textDecoration: 'underline'}}>HISTOLOGY</h3>
        <h4 style={{textAlign:'center'}}>Past papers</h4>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Year by Year</h5>
        <div className='container'>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2023</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1vABZufig2Ef5iBtMDSK5yBf6QjjvR_9K&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=184b_EWdhw61PLPGAgTeombUX9Zi6SRPw&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1mXmy73G-GhwHN0YU7u0E9muqUCBQwi2i&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2022</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=18SezC6GPxWqCGKfhYrq-L-Xccw4ex16S&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1pwNQW-McuR1DXQpPxDSDDTc1OpjXzCUP&export=download' style={styles.testText}>Test 2 Part 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1GVVyxuF2cTUlY_ovEWwJslN6seIWftjz&export=download' style={styles.testText}>Test 2 Part 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1qLjs1U5aGL03lhL2JWOYGbbncfNBMD_C&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1Xa-DZRp_qSbzzoLU6P3o41uxbHZ4R6UB&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2021</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1WDUYBC99GKmUIfYlAaaowE_eceL5wD9h&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=11hIyAodDQv8WiIj3jJJJn2iWJ7bYhnst&export=download' style={styles.testText}>Test 2 (Answer Schedule)</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2020</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1fPXAHWr14MESSw0BG_HxjY4yhfNyHOm7&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1JqX59N2VSl88dK7_pOvskXnFlnuaEQaK&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1jjuk_t6TVcnz_d_0OzkTNJKVcBW7aopu&export=download' style={styles.testText}>Sessional</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1c4L9pTpa7jpKYqUcuk0tQU7VbNektd1v&export=download' style={styles.testText}>Sessional (Answer Schedule)</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2019</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1N_2LC4N6nwBlZDuKUGH5xCEykefDTGRe&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1nI1N2JEJiucu0q35_gvWYzSFdgSKRBG4&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2018</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1cqgraiE5FVX-iWJJRukQWryMdFCM1osd&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1urreqsI0SF5TcyE3csvc7k5ypAZeTtxG&export=download' style={styles.testText}>Sessional Part 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1urreqsI0SF5TcyE3csvc7k5ypAZeTtxG&export=download' style={styles.testText}>Sessional Part 2</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2017</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1sWJduORHHK_zlpMydRS0cvXimhwjliPO&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2016</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1u54Dt6UNtT0PsKYgZpRum1g798BVaawD&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1U9ql4P8xX1BQOurSX2vSU6GSTO--3vyW&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2015</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1MOE5jLbTEZ7fHQ7Ke9wP4XH1ssOoLW6c&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1V1zURym1wXHYkCfJZpnESZxbnWWqIeNY&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2014</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1GPeVH6fDl57s_7RLdyfWAtTcaIJ4u5C2&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2013</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1b3kYI6JGllixqECayzkYXAj3wX8nQ2Oa&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1lEbfxOTaiw3Dysm_I1N4ECcbJfJCXys8&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1FPtZhxxiR3Mgc6lM1DDNNGMKRi3rWcI_&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
        </div>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Topic by Topic</h5>
        <div className='container'>
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Carbohydrates</a></div>
            <div style={styles.test}><a href='' style={styles.topicText}>Vitamins</a></div> */}
        </div>
        <h4 style={{textAlign:'center'}}>Notes</h4>
        <h4 style={{textAlign:'center'}}>Topical Revision Questions</h4>
    </div>
  )
}


const styles = {
    test: {
        padding: 4,
        margin: 6,
    },
    testText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(172, 200, 255)',
        borderRadius: 8
    },
    topicText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(255, 120, 155)',
        borderRadius: 8
    }
}