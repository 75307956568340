import React from 'react'
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

const STarLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  padding: 10px;
  margin: 0px;
  cursor: pointer;
  font-size: 23px;
  background: rgb(5, 15, 25);
  border-radius: 5px;
 

  &:hover{
    background: rgb(35,30,45);
    border-left: 3px solid white;  
    border-right: 3px solid white;  
    border-radius: 9px;
    
  }
  
  &.active{
    background: rgb(40,40,60);
    border: 3px solid white;
    border-radius: 9px;

  }



`



const SubMenu = ({item}) => {
  return (
    <>
        <div style={styles.linkers}>
          <STarLink to={item.path}>
                  <label style={{ cursor: 'pointer', fontSize: 20}}>{item.icon}</label>
                  <label style={{ cursor: 'pointer', fontSize: 16, marginLeft: 4, alignItems: 'center' }}>{item.title}</label>
          </STarLink>
        </div> 
        
    </>
  )
}

export default SubMenu;

const styles = ({
  linkers: {
    paddingTop: 8,
    paddingRight: 8,
    paddingBottom: 8,
    marginTop: 8,
    marginRight: 8,
    marginBottom: 8,
    textAlign: 'center'
  }
})