import {Helmet} from 'react-helmet-async';
import React from 'react'

import intro from '../../media/tutorials/sr/phy/introduction to physics.png';
import ele from '../../media/tutorials/sr/phy/electricity.png';
import eli from '../../media/tutorials/sr/phy/electromagnetic induction.png';
import elm from '../../media/tutorials/sr/phy/electromagnetism.png';
import forc from '../../media/tutorials/sr/phy/forces.png';
import gas from '../../media/tutorials/sr/phy/gas laws.png';
import lig from '../../media/tutorials/sr/phy/light.png';
import mag from '../../media/tutorials/sr/phy/magnetism.png';
import mas from '../../media/tutorials/sr/phy/mass, weight, volume and density.png';
import mec from '../../media/tutorials/sr/phy/mechanics.png';
import mom from '../../media/tutorials/sr/phy/moment of a force.png';
import rad from '../../media/tutorials/sr/phy/radioactivity.png';
import sim from '../../media/tutorials/sr/phy/simple machines.png';
import the from '../../media/tutorials/sr/phy/thermal physics.png';
import wav from '../../media/tutorials/sr/phy/wave motion and sound.png';
import wor from '../../media/tutorials/sr/phy/work, energy and power.png';


const price = 4;
// const message = "https://wa.me/message/WU3R7WHN5WYOE1";

export default function Physics() {
  // const click = () => {
  //   alert("You have just clicked on a purchased Tutorial, click OK to continue to WhatsApp")
  // }
  return (
    <div>
      <Helmet>
                <title>Physics</title>
                <meta 
                    name='description' 
                    content="Physics; what topic do you wish to understand?"
                    href="/physics"
                />
                <link rel="canonical" href='/physics'/>
            </Helmet>
      <h2 style={styles.header}>Physics</h2>
      <div className='grade10' style={styles.grade10}>
      <h3 style={{textAlign: "center", fontSize: 25, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder', color: 'rgb(12, 245, 96)'}}>Grade Ten</h3>
      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Introduction to Physics</x><br/><span className='badge' style={{color: 'yellow'}}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Qyinjn5YlooApB3CqSsSfDBT8a22uI3t&export=download'><img src={intro} height={187.04} width={132.32} style={styles.imb} alt="Introduction to Physics" className="products"/></a></p>
        <p style={styles.linkCon}><a href='/introduction_to_physics' style={styles.link10}>Brain Booster</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1mEeKSkYbEMFj837MTXd552EjWaltqE3v&export=download' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1cOUTngzDDZB7Hw7Yn0XjxZlZL52wRMyZ&export=download' style={styles.link10}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Mass, Weight, Volume & Density<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1XSKLUPS3kEXPTCL-_JsLqYFrxHoh2nMH&export=download'><img src={mas} height={187.04} width={132.32} style={styles.imb} alt="Mass, Weight, Volume & Density" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=15fXBEf97BT-WgFRwuX__LiQGePn5MDfb&export=download' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1VfJqKWdI7cqYFXFbBM9T5EPNRnL37z7b&export=download' style={styles.link10}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Forces<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1wp0a03Mv0bHckTptgXchW1VBICGkKyzS&export=download'><img src={forc} height={187.04} width={132.32} style={styles.imb} alt="Forces" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1RlUdePvDa3xyuKhgLJFCy3jfpFFP1TGW&export=download' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=12X4ph0BjpVmMPJ2qEi7lvrg4b5zNzVxh&export=download' style={styles.link10}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Mechanics<br/><span className='badge' style={{color: 'yellow'}}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Y2_P9h9nfLVRll8bzksaj6fn9IdhBPPy&export=download'><img src={mec} height={187.04} width={132.32} style={styles.imb} alt="Mechanics" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=10zFHY91RoFCytIyfPw8hkcV4HxRTfg9a&export=download' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1P4ixhUo9HQjz5UydQ4QuLwqcdToPEFv5&export=download' style={styles.link10}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Moments of a Force<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1ED5aB7T82aEVfXvC7LzolNl8W-ZwQk0J&export=download'><img src={mom} height={187.04} width={132.32} style={styles.imb} alt="Moments of a Force" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>



      

















      <div className='grade11' style={styles.grade11}>
      <h3 style={{textAlign: "center", fontSize: 25, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder', color: 'gold', paddingTop: 20}}>Grade Eleven</h3>
      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Thermal Physics</x><br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=17o6wsU4oQJrZ53sSaPcIKXoe06FmQyGy&export=download'><img src={the} height={187.04} width={132.32} style={styles.imb} alt="Thermal Physics" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Gas Laws<br/><span className='badge' style={{color: 'rgb(0,176,80)'}}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1U-Nkn0tRpKmvlZr9oMIxbPcgYghYWGos&export=download'><img src={gas} height={187.04} width={132.32} style={styles.imb} alt="Gas Laws" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1-2oT88ZXr_AChokZjzBgnidUGvtxTS4-&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1BE0k2yCvhfzk1IV88VyoHXaMOsLdIcRJ&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Waves<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1uNS2q9UaeCb3DDGy6IFWSon7OHyZVkd9&export=download'><img src={wav} height={187.04} width={132.32} style={styles.imb} alt="Waves" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1Rgx3AI0M1ij13Vfof68V4A5bRpRJ3_Gu&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=17d_8T-kz1LRzP8nBDeLUWzqdpK5UTafV&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Light<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1GDF_0qrHmbP6is1UBRQ9JSWyKiKV88M1&export=download'><img src={lig} height={187.04} width={132.32} style={styles.imb} alt="Light" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=11eAdZgCbTJC3zEcA1YJzJp7B9DaqN0NP&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1VoFcah7_SG83YKMhy0rFkiAL89xIyJwC&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Work, Energy and Power<br/><span className='badge' style={{color: 'rgb(0,176,80)'}}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1GOKmdbcFgLFCX8KIr2QUPpQf6Hryg3Ie&export=download'><img src={wor} height={187.04} width={132.32} style={styles.imb} alt="Work, Energy and Power" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1J3fJWpqjY4onYVX0O9Vj7isp3mFD6cx8&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=169zNgDQrkI6hlRI6qjfnwcc8ZwhGNEaw&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Simple Machines<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1maC8lYGWg3W5kfb5U5mGSOqe3GdvC9Kf&export=download'><img src={sim} height={187.04} width={132.32} style={styles.imb} alt="Simple Machines" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1iQcIWMLjufbCj-hQeKN5THTTM5_tUk8v&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=144OJSdpqehFUCxgTCdPcLNiEfdT8L-s4&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>





















      <div className='grade12' style={styles.grade12}>
      <h3 style={{textAlign: "center", fontSize: 25, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder', color: "red"}}>Grade Twelve</h3>
      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Magnetism</x><br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Z9TrKai0dRVBN6FGhHt1PNDViOtj3bXa&export=download'><img src={mag} height={187.04} width={132.32} style={styles.imb} alt="Magnetism" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1ih7cn8uQCMMsCwZdvuYvOyEMXaC3IH-A&export=download' style={styles.link12}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1DgvHhY8MNe_BEdsmBl1vSSDr_zMdbcLK&export=download' style={styles.link12}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Electromagnetism<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1-GlbcRtWN3KxC8JK0PFiV9xgLHUrhgJL&export=download'><img src={elm} height={187.04} width={132.32} style={styles.imb} alt="Electromagnetism" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Electricity<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1fTDmca41BImjagpjTt8xiqAFFSthQDTT&export=download'><img src={ele} height={187.04} width={132.32} style={styles.imb} alt="Electricity" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1A3Ro5gWLQtXzXrjMbeN4ryb5axQNQaEV&export=download' style={styles.link12}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1QLcZTZFU4xt_HG4B8W_fPrKGAOdN9xsz&export=download' style={styles.link12}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Electromagnetic Induction<br/><span className='badge' style={styles.price}>{price} ZMW</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1dOQG1nM6cv_XZr2fNdqJH88FxzpqNI9e&export=download'><img src={eli} height={187.04} width={132.32} style={styles.imb} alt="Electromagnetic Induction" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>RadioActivity<br/><span className='badge' style={{color: 'yellow'}}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1UPaWDP7RioI5oeIXK1BfifqzqmiC1BKb&export=download'><img src={rad} height={187.04} width={132.32} style={styles.imb} alt="RadioActivity" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>




    </div>
  )
}


const styles = ({
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: 36,
    paddingTop: 12
  },
  topicSelector: {
    textAlign: 'center',
    paddingTop: 5,
    fontSize: 18,
    fontFamily: 'Arial'
  },
  topicCon10: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  topicCon11: {
    border: "2px dotted black",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  topicCon12: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  image: {
    textAlign: 'center',
  },
  link10: {
    padding: 5,
    border: "1px solid white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  link11: {
    padding: 5,
    border: "1px solid black",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  link12: {
    padding: 5,
    border: "1px solid white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  },  
  linkCon: {
    marginBottom: 13,
    marginTop: 13,
    
  },
  price: {
    textDecoration: 'line-through',
    color: 'red',
    fontSize: 17
  },
  new: {
    fontWeight: 'bolder',
    color: 'rgb(12, 200, 150)',
  },
  imb: {
    borderRadius: 5,
  },
  grade10: {
    backgroundImage: 'linear-gradient(white, rgb(100, 100, 100), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  },
  grade11: {
    backgroundImage: 'linear-gradient(white, rgb(0, 128, 255), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  },
  grade12: {
    backgroundImage: 'linear-gradient(white, rgb(32, 170, 73), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  }
})