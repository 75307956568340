import React from 'react'

export default function PublicHealth() {
  return (
    <div><br/>
        <h3 style={{textAlign:'center', textDecoration: 'underline'}}>PUBLIC HEALTH</h3>
        <h4 style={{textAlign:'center'}}>Past papers</h4>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Year by Year</h5>
        <div className='container'>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2023</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1i9mWRy9gvPvVvyFcleiNV_KW85H5q5Tk&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2022</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1grTQxVz5ohYw3uqcfcolGUgCbYbJgbct&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1KC1i6mjzm-s7ckl-yl1mqyseHibWpdW5&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2021</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1_IvAYpT6A44-TzUPSJlv8jztiXU4w8Px&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2020</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=10-PcntKWPRekz0rVbrRZraHL6LchjNlu&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1-cpufE2MeeVDPz2RlTQSryA__TpgMFh0&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2019</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1eqflKICH0MNzmm_GeuZlhD7Nzl79VWmA&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1gcKnf7gq7Xeip2q5vmHJs3s36V3DgAAh&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            {/* <div style={{fontWeight: 'bolder', fontSize: 19}}>2018</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div> */}
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2017</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1bXXWfAYlaEvvy5LpRl8jr1sjzhGUut5-&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1-Mnj1ys2AIT3GMxQAQvo4NXD-mN87GPP&export=download' style={styles.testText}>Test 3</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            {/* <div style={{fontWeight: 'bolder', fontSize: 19}}>2016</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div> */}
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2015</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1OzD_YyLXJ0cXqO53gFA1-o06k99kJkp1&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1JDG9JdGkjzi6P1NCRg74DkOx8HZ5bl5R&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2014</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1xX2-Emp65CX41tBCjZ8TNz4RzXwB7Xac&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            {/* <div style={{fontWeight: 'bolder', fontSize: 19}}>2013</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div> */}
        </div>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Topic by Topic</h5>
        <div className='container'>
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Carbohydrates</a></div>
            <div style={styles.test}><a href='' style={styles.topicText}>Vitamins</a></div> */}
        </div>
        <h4 style={{textAlign:'center'}}>Notes</h4>
        <h4 style={{textAlign:'center'}}>Topical Revision Questions</h4>
    </div>
  )
}


const styles = {
    test: {
        padding: 4,
        margin: 6,
    },
    testText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(172, 200, 255)',
        borderRadius: 8
    },
    topicText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(255, 120, 155)',
        borderRadius: 8
    }
}