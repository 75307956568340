import {Helmet} from 'react-helmet-async';

import React, {useState, useEffect} from 'react';

// import loader from "../media/loader.gif"

export default function Smartify(){
    const [smartify, setSmartify] = useState([])
    useEffect(()=>{
        //
        fetch("https://michealzsd.pythonanywhere.com/List/", {
            method: 'GET',
            headers: {
                "content-type": "application/json"
            }
        }).then(resp => resp.json())
        .then(resp => setSmartify(resp))
        .catch(error => console.log(error))
    }, [])
    return (
        <div style={{fontSize: 16}}><br/>
            <Helmet>
                <title>STudy STar: Smartify Page</title>
                <meta 
                    name='description' 
                    content="How smart are you? Can you become smarter? Click this link to find out"
                    href="/smartify"
                />
                <link rel="canonical" href='/smartify'/>
            </Helmet>
            <h1 style={styles.h1}>SMARTIFY PAGE</h1>
            <div>
                {
                    smartify.length !== 0 ? 
                    <div className='container'>
                        <div>{smartify.slice(0).reverse().map(smart => {
                            return (<div key={smart.title}>
                                <hr/>
                                <h3 style={styles.title}>{smart.title}</h3>
                                <p style={{textAlign: 'right'}}><small>{smart.date}</small><br/><small>{smart.time}</small></p>
                                <br/><p style={{textAlign: 'center'}}><a href={`/smartify/${smart.id}`}><img src={smart.firstImage} height={200} width={300} alt={smart.title}/></a></p><br/>
                                <p style={{
                                    textIndent: 20, 
                                    fontFamily: '"Raleway", sans-serif',
                                    fontOpticalSizing: 'auto',
                                    fontWeight: 300,
                                    fontStyle: 'normal', 
                                    fontSize: 17,
                                }}>
                                    {smart.introFirst}
                                </p>
                                <p style={{textAlign: 'right', fontSize: 17}}><a href={`/smartify/${smart.id}`}>finish...</a></p>
                                
                                </div>)
                        })}</div>
                    </div>
                    
                    : <div style={{textAlign: 'center'}}>
                        <div className='lc'>
                            <d className="loader"></d>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

{/* <img src={loader} height={110} width={110} alt="STudy STar loader"/> */}

const styles = ({
    title: {
        fontFamily: '"Newsreader", serif',
        fontOpticalSizing: 'auto',
        fontWeight: 600,
        fontStyle: 'normal',
        textAlign: 'center'
    },
    para: {
        fontFamily: 'arial',
        textAlign: 'center',
    },
    h1: {
        textAlign: 'center', 
        textDecoration: 'underline', 
        fontFamily: "tahoma", 
        textDecorationColor: 'red',
        fontWeight: 900,
    }
})