import React from 'react'

export default function Physiology() {
  return (
    <div><br/>
        <h3 style={{textAlign:'center', textDecoration: 'underline'}}>PHYSIOLOGY</h3>
        <h4 style={{textAlign:'center'}}>Past papers</h4>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Year by Year</h5>
        <div className='container'>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2023</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1JijsHxhLWNrz8jiU-7pJbHXYvcHTPSMM&export=download' style={styles.testText}>Test 3 Part A</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1zC420BCLYrZ8mEihzQAlzy2eKuJMlPcT&export=download' style={styles.testText}>Test 3 Part B</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2022</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1Xb7NvSoExbRugPzCYZQUWTqDvVJiA2fG&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1m5OCzlK1Du6y4S0ROOwdDzI3ppxT6_F4&export=download' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1Y3G3MDTNyosQmKd189x6qLHUjm9UozJG&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1o03hWfz78Z1qZccQ77WNv9C4j8xcYJ9n&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2021</div>
           <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1_SmjZFJzjAjBoqt7o_aTZtHNbGgfCHVy&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1jKZG06G8SBEdYOsOlPp9J09rCyFBwR0l&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1FGhod9K3YonxVfRi_Y_ylnevXORlQTd7&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2020</div>
           <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1Lmi1YEY4CXuQ2OSt2Ml6vXwF4QuMygmu&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2019</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=14T6cHvFjbDkYwjlVDodavMR0p3kKuuMO&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1F8iZTK3lExIGG68m9b-c69cXffdbfmjN&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2018</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1gHt-d42fxpu4O2hxOEhOCFeVKXSYKGnZ&export=download' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2017</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1h8paZQHbpDoHg8tTx3uLzYxR1LVqu4CA&export=download' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1EuNKRrWaHWv04n3R5WsfiI0QZau0ohp0&export=download' style={styles.testText}>Test 2</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            {/* <div style={{fontWeight: 'bolder', fontSize: 19}}>2016</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2015</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2014</div>
            <div className='container'>
                <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div>
            </div> */}
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2013</div>
            <div className='container'>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=15N5ewjW6X054QZKGzpd1Of5uZgv4A0Rx&export=download' style={styles.testText}>Test 1</a></div>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 3</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Sessional</a></div> */}
            </div>
            <div style={{fontWeight: 'bolder', fontSize: 19}}>2012</div>
            <div className='container'>
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 1</a></div> */}
                {/* <div style={styles.test}><a href='' style={styles.testText}>Test 2</a></div> */}
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1o3KFP5hSxMD-RnOumXjXD3U6kBYBw8jB&export=download' style={styles.testText}>Test 3</a></div>
                <div style={styles.test}><a href='https://drive.google.com/uc?id=1EHOkIjh9LmnQDnqjwPTIBSuikVW_anJP&export=download' style={styles.testText}>Sessional</a></div>
            </div>
        </div>
        <h5 style={{textAlign:'center', textDecoration: 'underline', textDecorationColor: 'rgb(134, 197, 255)'}}>Topic by Topic</h5>
        <div className='container'>
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Carbohydrates</a></div> */}
            {/* <div style={styles.test}><a href='' style={styles.topicText}>Vitamins</a></div> */}
        </div>
        <h4 style={{textAlign:'center'}}>Notes</h4>
        <h4 style={{textAlign:'center'}}>Topical Revision Questions</h4>
    </div>
  )
}


const styles = {
    test: {
        padding: 4,
        margin: 6,
    },
    testText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(172, 200, 255)',
        borderRadius: 8
    },
    topicText: {
        padding: 8,
        textDecoration: 'none',
        fontSize: 18,
        backgroundColor: 'rgb(255, 120, 155)',
        borderRadius: 8
    }
}