import {Helmet} from 'react-helmet-async';
import React from 'react'


import simultaneous from '../../media/tutorials/sr/mat/simultaneous equations.png'
import intro from '../../media/tutorials/sr/mat/introduction to mathematics.png';
import alg from '../../media/tutorials/sr/mat/algebra.png';
import ang from '../../media/tutorials/sr/mat/angle properties of a circle.png';
import angs from '../../media/tutorials/sr/mat/angles.png';
import ari from '../../media/tutorials/sr/mat/arithmetic progression (AP).png';
import bea from '../../media/tutorials/sr/mat/bearings.png';
import dif from '../../media/tutorials/sr/mat/calculus (Differentiation).png';
import int from '../../media/tutorials/sr/mat/calculus (Integration).png';
import com from '../../media/tutorials/sr/mat/computer.png';
import coo from '../../media/tutorials/sr/mat/coordinate geometry.png';
import ear from '../../media/tutorials/sr/mat/earth geometry.png';
import fun from '../../media/tutorials/sr/mat/functions.png';
import geo from '../../media/tutorials/sr/mat/geometric progression (GP).png';
import ind from '../../media/tutorials/sr/mat/indices.png';
import mat from '../../media/tutorials/sr/mat/matrices.png';
import men from '../../media/tutorials/sr/mat/mensuration.png';
import pro from '../../media/tutorials/sr/mat/probability.png';
import que from '../../media/tutorials/sr/mat/quadratic equations.png';
import quf from '../../media/tutorials/sr/mat/quadratic functions.png';
import set from '../../media/tutorials/sr/mat/sets and numbers.png';
import sim from '../../media/tutorials/sr/mat/similarity and congruence.png';
import tra from '../../media/tutorials/sr/mat/travel graphs.png';
import tri from '../../media/tutorials/sr/mat/trigonometry.png';
import vari from '../../media/tutorials/sr/mat/variation.png';
import vec from '../../media/tutorials/sr/mat/vectors.png';


const price = 4;
// const message = "https://wa.me/message/WU3R7WHN5WYOE1";


export default function Mathematics() {
  // const click = () => {
  //   alert("You have just clicked on a purchased Tutorial, click OK to continue to WhatsApp")
  // }
  return (
    <div>
      <Helmet>
                <title>Mathematics</title>
                <meta 
                    name='description' 
                    content="Mathematics? What topic do you wish to understand?"
                    href="/mathematics"
                />
                <link rel="canonical" href='/mathematics'/>
            </Helmet>
      <h2 style={styles.header}>Mathematics</h2>
      <div className='grade10' style={styles.grade10}>
      <h3 style={{textAlign: "center", fontSize: 25, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder', color: 'rgb(12, 245, 96)'}}>Grade Ten</h3>
      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Introduction to Mathematics</x><br/><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=10iwBl2Z9orXFty39UFCOZPSwkaakDL7s&export=download'><img src={intro} height={187.04} width={132.32} style={styles.imb} alt="Introduction to Mathematics" className="products"/></a></p>
        
        <p style={styles.linkCon}><a href='/introduction_to_mathematics' style={styles.link10}>Brain Booster</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=14GF1L3AG2SBv0xZWfv0jMsC00ij8J4O3&export=download' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1XObnvyQgmWw8ICkmO4MFkCo0iHmH3vfO&export=download' style={styles.link10}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Sets</x><br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=16GIjjG1AFDhwVwGziAkcC1n4JG-21jj5&export=download'><img src={set} height={187.04} style={styles.imb} width={132.32} alt="Sets" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Algebra<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Z1xr15w8AKE14UIQAMdDSlm--6NIft2o&export=download'><img src={alg} height={187.04} style={styles.imb} width={132.32} alt="Algebra" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1zHjuJB5yeszuPyRFVXHbaOr6_GS2QMCo&export=download' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1jstN23g33cD8eSoIYSkR82whdpktB6Kg&export=download' style={styles.link10}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Indices<br/><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1NpQZu_La8NdoeM8IOjrAIbGGV28l1zb1&export=download'><img src={ind} height={187.04} width={132.32} style={styles.imb} alt="Indices" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=18_RjSk0XOCTyn_6fxxbmFcsP8u5pZvuJ&export=download' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1zan6VKV3J9DTgnQW-V1t3-sshG-uR6cz&export=download' style={styles.link10}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Matrices<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=176V9ojDnpkouxrsjvu64KuMyowZ0KBTK&export=download'><img src={mat} height={187.04} style={styles.imb} width={132.32} alt="Matrices" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Simultaneous Equations<br/><span className='badge' style={styles.price}> {price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1RneC29L0DJ5bf4ZHMDhCvxDKl15IHKay&export=download'><img src={simultaneous} height={187.04} style={styles.imb} width={132.32} alt="Matrices" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>


      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Travel Graphs<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1PS8HqUQ7z78VkoQWk8hl02nx_p9mcQTY&export=download'><img src={tra} height={187.04} style={styles.imb} width={132.32} alt="Travel Graphs" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Similarity and Congruence<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1SJiV-rREzLT4_T3wZ-zehwXBb1wxCu1C&export=download'><img src={sim} height={187.04} style={styles.imb} width={132.32} alt="Similarity and Congruence" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link10}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}>Angles<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1pUWU2GlYUTvBDuCGBZ_HTYgoEP5Wg9LM&export=download'><img src={angs} height={187.04} style={styles.imb} width={132.32} alt="Angles" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1qGNrIaKWZtgdXWVJkF1Y8vDv7-QtGoy4&export=download' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1xwy6H4-S2wLzQI6TUuX34BCCwPESUywk&export=download' style={styles.link10}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon10}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Bearings</x><br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1yYukE6HC7O6IjCLqHIrFJvgKMlzuY6fu&export=download'><img src={bea} height={187.04} style={styles.imb} width={132.32} alt="Bearings" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link10}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=112KJAQ1pjwn7ZK2ECfGvNEevjrl01I1b&export=download' style={styles.link10}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1motH7gC7IWGUgCJCSS8_rIdNQcq3YStI&export=download' style={styles.link10}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>





































      <div className='grade11' style={styles.grade11}>
      <h3 style={{textAlign: "center", fontSize: 25, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder', color: 'gold', paddingTop: 20}}>Grade Eleven</h3>
      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Coordinate Geometry</x><br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Q6EnFCA8BZc_qppPSrKO4oEQIr4XtX4y&export=download'><img src={coo} height={187.04} style={styles.imb} width={132.32} alt="Coordinate Geometry" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1-BHkrL7SyEymgt-gDkrUOt5AQuFgiIbA&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1kEMTiH9VvktKWTqa5LwlRSMEiMIMbdIj&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Functions<br/><span className='badge' style={{color: 'rgb(0,176,80)'}}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1WpEI50WmMi5dCoVI4YTxd7A_hCEvkcFd&export=download'><img src={fun} height={187.04} width={132.32} style={styles.imb} alt="Functions" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1O7I2U_Xu90KZQ3r395VtKVdsVPB0XwLP&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1zY5rbKeKejVe_t1wzcG0T4IkVFlleFjn&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Variation<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1uJL_WMuyeb3iBIZhEW6f216JcU00UgAH&export=download'><img src={vari} height={187.04} style={styles.imb} width={132.32} alt="Variation" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=18Dol9ZZakQAOgClN-ReyX_WYliUmHrcK&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1fKU0Q0BqE_ItDdaN_5eIywO7AHuUl3JD&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Quadratic Equations<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1kpRPW5eGJzEk0Mb75z8kIY4wW0XibmCl&export=download'><img src={que} height={187.04} style={styles.imb} width={132.32} alt="Quadratic Equations" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Quadratic Functions<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Jfdm9fz2U70ZQvYNrC5BK1Fl85wdTsTA&export=download'><img src={quf} height={187.04} style={styles.imb} width={132.32} alt="Quadratic Functions" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Arithmetic Progression<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1lGdPtXp5YtmkrDs5zGg_nVN1iDgNzNRj&export=download'><img src={ari} height={187.04} style={styles.imb} width={132.32} alt="Arithmetic Progression" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1ya85kkCVSMvkjGjg3L5dKLLT9nLT5cvQ&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1d9hRZCKfCGuKN3WaWrBNd9Pz8rI2S0QL&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Geometric Progression<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1pXhgyKXKJSHKOu0mjZkHqzBoKBlJy1kE&export=download'><img src={geo} height={187.04} style={styles.imb} width={132.32} alt="Geometric Progression" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1j3rMCs_-lgD4H9cYH3l4nhh-s3L0lKsk&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1cZyhs0w0ttJic5_GNptow_SoaQtCMtDr&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Circle Properties<br/><span className='badge' style={{color: 'rgb(0,176,80)'}}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1i-mh6cHqYEzduD35WTXoQhrw9rYn0zVM&export=download'><img src={ang} height={187.04} width={132.32} style={styles.imb} alt="Circle Properties" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1skr_lEGlAh66qhXJdRUa2EtuKV8FzW5U&export=download' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1YuOmLWUr4E1DsNQjnOwRm-hR_L1X1fF7&export=download' style={styles.link11}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Mensuration<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1-w0qD2oC99odODvO4Cr6Tm8CxfRnV6KK&export=download'><img src={men} height={187.04} style={styles.imb} width={132.32} alt="Mensuration" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}>Vectors<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1Ato8UzJ1-rd3TkW4x9qJxLuprTyYxuwE&export=download'><img src={vec} height={187.04} style={styles.imb} width={132.32} alt="Vectors" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon11}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Probability</x><br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1MLbWwfre0hEScUVyitnZwQK_5gQNu_fS&export=download'><img src={pro} height={187.04} style={styles.imb} width={132.32} alt="Probability" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link11}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>





      

















      <div className='grade12' style={styles.grade12}>
      <h3 style={{textAlign: "center", fontSize: 25, fontFamily: 'tahoma sans-serif', fontWeight: 'bolder', color: "red"}}>Grade Twelve</h3>
      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}><x style={{color: '#0066FF', }}>Computer (PseudoCode and Flow Charts)</x><br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1g9esFp_Wtw6O5YufTLzyZ55dEABVrblr&export=download'><img src={com} height={187.04} style={styles.imb} width={132.32} alt="Computer (PseudoCode and Flow charts)" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1nA_WRjNhAuWTnRmptK0ZUvtjzpjNvBiC&export=download' style={styles.link12}>Practice Test</a><br/></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Earth Geometry<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1OuHVLcznNtX4TUmSGfrorGz6h7nKWB6d&export=download'><img src={ear} height={187.04} style={styles.imb} width={132.32} alt="Earth Geometry" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1TD-ymEobcNQtjcGPzbwIUVHT-f43MvOs&export=download' style={styles.link12}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1jQ6fDlo4OQbE-rD6bJyAUsmxcjDg1ZPe&export=download' style={styles.link12}>Practice Test Answers</a></p>
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Trigonometry<br/><span className='badge' style={styles.new}>FREE</span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1kaSipS3MMPuzaP0BoPoQxbS_XhDhOO7I&export=download'><img src={tri} height={187.04} width={132.32} style={styles.imb} alt="Trigonometry" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Calculus: Differentiation<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1nntjkiOsbLrpmQxgdXrnBqLiExGwL1s0&export=download'><img src={dif} height={187.04} style={styles.imb} width={132.32} alt="Calculus: Differentiation" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        <p style={styles.linkCon}><a href='https://drive.google.com/uc?id=1iuAatrkmQ-DlXcvcIcBoOocjsV8T8fMV&export=download' style={styles.link12}>Practice Test</a><br/></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>

      <div style={styles.topicCon12}>
        <h4 style={styles.topicSelector}>Calculus: Integration<br/><span className='badge' style={styles.price}>{price} ZMW </span></h4>
        <p style={styles.image}><a href='https://drive.google.com/uc?id=1wIyu7esKc3jPW9cb3yzbS1cqADSqXxCu&export=download'><img src={int} height={187.04} style={styles.imb} width={132.32} alt="Calculus: Integration" className="products"/></a></p>
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Brain Booster</a><br/></p> */}
        {/* <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test</a><br/></p>
        <p style={styles.linkCon}><a href='' style={styles.link12}>Practice Test Answers</a></p> */}
        {/* <p>
          <audio src='' controls="controls" style={{width: '96%'}}/>
          <video src='' height={150} width={250} controls="controls"/>
        </p> */}
      </div>
      </div>







    </div>
  )
}



const styles = ({
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: 36,
    paddingTop: 12
  },
  topicSelector: {
    textAlign: 'center',
    paddingTop: 5,
    fontSize: 18,
    fontFamily: 'Arial'
  },
  topicCon10: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  topicCon11: {
    border: "2px dotted black",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  topicCon12: {
    border: "2px dotted white",
    marginLeft: "5%",
    marginRight: "5%",
    borderRadius: 20,
    textAlign: 'center',
    marginBottom: 20
  },
  image: {
    textAlign: 'center',
  },
  link10: {
    padding: 5,
    border: "1px solid white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  link11: {
    padding: 5,
    border: "1px solid black",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  }, 
  link12: {
    padding: 5,
    border: "1px solid white",
    borderRadius: 5,
    fontWeight: 'bolder',
    textDecoration: 'none',
    color: 'blue'
  },  
  linkCon: {
    marginBottom: 13,
    marginTop: 13,
    
  },
  price: {
    textDecoration: 'line-through',
    color: 'red',
    fontSize: 17
  },
  new: {
    fontWeight: 'bolder',
    color: 'rgb(12, 200, 150)',
  },
  imb: {
    borderRadius: 5,
  },
  grade10: {
    backgroundImage: 'linear-gradient(white, gold, white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  },
  grade11: {
    backgroundImage: 'linear-gradient(white, rgb(0, 128, 255), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  },
  grade12: {
    backgroundImage: 'linear-gradient(white, rgb(32, 170, 73), white)', 
    color: 'whitesmoke', 
    paddingTop: 30, 
    paddingBottom: 30
  }
})