import React from 'react';

import bgImage from "../media/bg.jpg";

export default function Footer(){
    return (
        <div style={styles.container}>
            <p style={{fontSize: 18}}><b>STudy STar</b>: A top student's secret tool.<br/> A branch of STar Tech Industries &copy; 2024 </p>
        </div>
    )
}

const styles = {
    container: {
        textAlign: "center", 
        padding: 8, 
        marginTop: 2, 
        marginBottom: 2, 
        backgroundImage: `linear-gradient(rgba(0,0,0,.89),rgba(0,0,0,1)) ,url(${bgImage})`, 
        color: "white", 
        paddingBottom: 20, 
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'center', 
        backgroundSize: 'cover',
        fontFamily: 'times new romans'
        
    }
}